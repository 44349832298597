import { Component, Output, EventEmitter, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '../services/config.service';
import { UserService } from '../../user/user.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { Router, NavigationEnd } from '@angular/router';
import { NgbModal, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { EditProfileScreenComponent } from '../../user/modals/editprofile.screen.component';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
  providers: [
    UserService
  ]
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  layoutSub: Subscription;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();

  public config: any = {};

  constructor(public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private _userService: UserService,
    private _flashMessagesService: FlashMessagesService,
    private router: Router,
    private modalService: NgbModal,
  ) {
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

    this.layoutSub = layoutService.changeEmitted$.subscribe(
      direction => {
        const dir = direction.direction;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      });
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }

  ngAfterViewInit() {
    if (this.config.layout.dir) {
      setTimeout(() => {
        const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
      }, 0);

    }
  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitNotiSidebarChange(true);
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  logout() {
    this._userService.checkUserSession().then((isSessionValid) => {
      if(isSessionValid) {
        this._userService.logout().subscribe(
          (logoutData) => {
            if (logoutData.status == 'success') {
              localStorage.setItem('loginToken', '');
              localStorage.setItem('logUserEmail', '');
              localStorage.setItem('selectedMonthYearLocalStorage', '');
              localStorage.setItem('selectedMonthYearLocalStorageUpload', '');
              localStorage.setItem('mapDataManufacturerName', '');
              this._flashMessagesService.show(logoutData.message, { cssClass: 'alert-success', timeout: 4000 });          
              this._userService.signOutWithCognito();
              this.router.navigate(['/login']);
            }
          },
          (error) => { }
        )
      }
    })
  }
  editProfile() {
    const modalRef = this.modalService.open(EditProfileScreenComponent, {
      windowClass: 'editProfileclass'
    });
  }
}
