import { Routes, RouterModule } from '@angular/router';

//Layout for login and forgot passowrd pages.
export const CONTENT_ROUTES: Routes = [
    {
        path: 'pages',
        loadChildren: () => import('../../pages/content-pages/content-pages.module').then(m => m.ContentPagesModule)
    },
    {
        path: 'login',
        loadChildren: () => import('../../login/forms.module').then(m => m.FormModule)
    }
];
