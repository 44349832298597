import { Component, ViewEncapsulation, Input, OnInit, ViewChild, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HelperService } from '../../core/helper';
import { SalespeopleHelper } from '../salespeople.helper';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-editsalarydraw-screen',
  templateUrl: './editsalarydraw.screen.component.html',
  styleUrls: ['./editsalarydraw.screen.component.scss'],
  providers: [
    HelperService,
    SalespeopleHelper,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class EditSalaryDrawScreenComponent implements OnInit {
  salaryDrawValidationList: any;
  editSalaryDrawResponse: any;
  salaryDrawStartMonthList: any;
  salaryDrawEndMonthList: any;
  salaryDrawStartMonthYearEle = new FormControl();
  salaryDrawEndMonthYearEle = new FormControl();
  salaryDrawEndMonthMinLimit: any;
  salaryDrawNoneChecked: boolean;
  salaryDrawStartMonthYearFilter: any;
  salaryDrawEndMonthMaxLimit: any;
  drawNoneVisible: boolean;
  @Input() salaryDrawStartMonthYear;
  @Input() salaryDrawEndMonthYear;
  @Input() drawAmount;
  @Input() isLastEntry;
  @Input() drawNote;
  @Input() selectedManufacturerDraw;
  @Input() drawManufacturerCustomOption;
  @Input() manufacturers;
  @Input() disabledSalaryDrawMonthList;
  @Input() disabledGuaranteeMonthList;
  @Input() salaryDrawList;
  @Input() guaranteeList;
  constructor(
    private spinner: NgxSpinnerService,
    private _helper: HelperService,
    private _salespeopleHelper: SalespeopleHelper,
    public activeModal: NgbActiveModal) {
  }
  ngOnInit() {
    this.salaryDrawValidationList = { manufacturers: "", drawAmount: "" };
    this.editSalaryDrawResponse = {};
    this.salaryDrawStartMonthList = [];
    this.salaryDrawStartMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.salaryDrawStartMonthYear));
    const guaranteeLastRecordEndMonth = (this.guaranteeList.length > 0) ? this.guaranteeList[this.guaranteeList.length -1 ].endMonth : '';
    const drawLastRecordEndMonth = (this.salaryDrawList.length > 0) ? this.salaryDrawList[this.salaryDrawList.length -1].endMonth : '';
    const drawLastRecordStartMonthDate = (this.salaryDrawList.length > 0) ? this.salaryDrawList[this.salaryDrawList.length -1].startDate : '';
    const guaranteeLastRecordStartMonthDate = (this.guaranteeList.length > 0) ? this.guaranteeList[this.guaranteeList.length -1].startDate: ''; 
    if (this.salaryDrawEndMonthYear !== 'None' && guaranteeLastRecordEndMonth !== 'None') {
      this.salaryDrawNoneChecked = false;
      this.drawNoneVisible = false;
    } else {
      this.salaryDrawNoneChecked = true;
      this.drawNoneVisible = true;
    }
    this.updateDrawEndMonthPicker(this.salaryDrawStartMonthYear, 'onload');
    this.salaryDrawStartMonthYearFilter = (d: Date) => {
      const monthYear = moment(d).format("MMMM-YYYY");
      if (this.disabledSalaryDrawMonthList.includes(monthYear) || this.disabledGuaranteeMonthList.includes(monthYear)) {
        let salaryDrawEndMonthYear;
        if (this.salaryDrawEndMonthYear == 'None') {
          salaryDrawEndMonthYear = this._helper.getFormattedMonthYear("December-9999");
        } else {
          salaryDrawEndMonthYear = this.salaryDrawEndMonthYear;
        }
        if (moment(d).isBetween(moment(this.salaryDrawStartMonthYear), moment(salaryDrawEndMonthYear), null, '[]')) {
          return true;
        } else {
          return false;
        }
      } else {
        if(
          (guaranteeLastRecordEndMonth === 'None' && moment(d).isAfter(moment(guaranteeLastRecordStartMonthDate)))
          || (drawLastRecordEndMonth === 'None' && this.salaryDrawEndMonthYear !== 'None' && moment(d).isAfter(moment(drawLastRecordStartMonthDate)))
          ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
  updateDrawEndMonthPicker(salaryDrawStartMonth, source) {
    const drawStartDate = moment(salaryDrawStartMonth);
    const drawGuaranteeList = this.salaryDrawList.concat(this.guaranteeList);
    for (const drawGuarantee of drawGuaranteeList) {
      // List start month date.
      const startMonthDate = this._helper.getFormattedMonthYear(drawGuarantee.startMonth);
      if (drawStartDate >= startMonthDate) {
        // show none toggole button if selected start date is greater than last entry.
        this.drawNoneVisible = true;
        this.salaryDrawEndMonthMaxLimit = this._helper.getFormattedMonthYear("December-9999");
      } else {
        // hide none toggle button if selected start date is of middle months.
        this.drawNoneVisible = false;
        this.salaryDrawEndMonthMaxLimit = this._helper.getFormattedMonthYear(drawGuarantee.startMonth).subtract(1, "M");
        break;
      }
    }
    if (!this.drawNoneVisible || (this.drawNoneVisible && !this.salaryDrawNoneChecked)) {
      if (source == 'onchange') {
        this.salaryDrawEndMonthYear = moment(salaryDrawStartMonth).format("MMMM-YYYY");
        this.salaryDrawEndMonthYearEle.setValue(moment(salaryDrawStartMonth));
        this.salaryDrawEndMonthMinLimit = moment(salaryDrawStartMonth);
      } else {
        this.salaryDrawEndMonthYearEle.setValue(moment(this.salaryDrawEndMonthYear));
        this.salaryDrawEndMonthMinLimit = moment(this.salaryDrawStartMonthYear);
      }

    }
  }
  updateSalaryDraw() {
    const isSalaryDrawValid = this.performSalaryDrawValidation();
    if (isSalaryDrawValid) {
      this.editSalaryDrawResponse["drawAmount"] = this.drawAmount;
      this.editSalaryDrawResponse["drawNote"] = this.drawNote;
      this.editSalaryDrawResponse["selectedManufacturerDraw"] = this.selectedManufacturerDraw;
      this.editSalaryDrawResponse["drawManufacturerCustomOption"] = (this.drawManufacturerCustomOption) ? this.drawManufacturerCustomOption.trim() : "";
      this.editSalaryDrawResponse["startMonth"] = this.salaryDrawStartMonthYear;
      this.editSalaryDrawResponse["endMonth"] = this.salaryDrawEndMonthYear;
      this.activeModal.close(this.editSalaryDrawResponse);
    }
  }
  performSalaryDrawValidation() {
    let isSalaryDrawValid = true;
    this.salaryDrawValidationList = { drawAmount: "" };
    if (this.drawAmount === "") {
      isSalaryDrawValid = false;
      this.salaryDrawValidationList.drawAmount = "This field is required!";
    } else if (this.drawAmount == 0) {
      isSalaryDrawValid = false;
      this.salaryDrawValidationList.drawAmount = "Zero value not allowed!";
    } else if (!this.drawAmount.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      isSalaryDrawValid = false;
      this.salaryDrawValidationList.drawAmount = "Only number allowed";
    }
    const {
      manufacturerIsValid,
      manufacturerValidationList,
      manufacturerCustomOption
    } = this._salespeopleHelper.validateManufacturerWithoutRequired(
      {
        selectedManufacturer: this.selectedManufacturerDraw,
        validationList: this.salaryDrawValidationList,
        manufacturerCustomOption: this.drawManufacturerCustomOption,
        isValid: isSalaryDrawValid,
        manufacturers: this.manufacturers   
      }
    );
    isSalaryDrawValid = manufacturerIsValid;
    this.salaryDrawValidationList = manufacturerValidationList;
    this.drawManufacturerCustomOption = manufacturerCustomOption;
    return isSalaryDrawValid;

  }
  closePopup() {
    this.activeModal.close({});
  }
  chosenSalaryDrawStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.salaryDrawStartMonthYear = moment(normalizedMonth).format("MMMM-YYYY");
    this.salaryDrawStartMonthYearEle.setValue(moment(normalizedMonth));
    this.salaryDrawEndMonthMinLimit = moment(normalizedMonth);
    this.updateDrawEndMonthPicker(normalizedMonth, 'onchange');
    datepicker.close();
  }
  chosenSalaryDrawEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.salaryDrawEndMonthYear = moment(normalizedMonth).format("MMMM-YYYY");
    this.salaryDrawEndMonthYearEle.setValue(moment(normalizedMonth));
    datepicker.close();
  }
  onSalaryDrawNoneChange(ob: MatSlideToggleChange) {
    if (ob.checked) {
      this.salaryDrawEndMonthYear = 'None';
    } else {
      this.salaryDrawEndMonthYear = this.salaryDrawStartMonthYear;
      this.salaryDrawEndMonthYearEle.setValue(moment(this.salaryDrawEndMonthYear));
      this.salaryDrawEndMonthMinLimit = moment(this.salaryDrawEndMonthYear);
    }
    this.salaryDrawNoneChecked = ob.checked;
  }
}
