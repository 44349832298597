import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../core/config';

@Injectable()
export class RegionsService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }
  getRegionsList(showDeletedRecords): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/regions/getRegionsList/${showDeletedRecords}`);
  }
  unAssignTerritory(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/regions/unAssignTerritory`, params);
  }
  deleteRegion(id): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/regions/deleteRegion/${id}`);
  }
  addRegion(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/regions/addRegion`, params);
  }
  updateRegion(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/regions/updateRegion`, params);
  } 

}
