import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config';

@Injectable({ providedIn: 'root' })
export class DataService {
  private loadingTextSource = new BehaviorSubject('');
  currentText$ = this.loadingTextSource.asObservable();
  constructor(private configService: ConfigService) { }
  changeLoadingText(loadingText: string) {
    this.configService.setEnvironment();
    this.loadingTextSource.next(loadingText);
  }
}

