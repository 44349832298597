import { Component, Input, OnInit } from '@angular/core';
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HelperService } from '../../core/helper';
import { SalespeopleHelper } from '../salespeople.helper';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@Component({
    selector: 'app-editbonusrule-screen',
    templateUrl: './editbonusrule.screen.component.html',
    styleUrls: ['./editbonusrule.screen.component.scss'],
    providers: [
        HelperService,
        SalespeopleHelper,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class EditBonusRuleScreenComponent implements OnInit {
    isRegionOptionUsed: boolean;    
    bonusRulesValidationList: any = {
        manufacturers: '',
        territories: '',
        growthBonus: '',
        procedures: '',
        surgeons: '',
        hospitals: ''
    };
    editBonusRuleResponse: any = {};
    
    @Input() procedures;
    @Input() manufacturers;
    @Input() hospitals;
    @Input() surgeons;
    @Input() territories;
    @Input() regions;
    @Input() growthBonus;
    @Input() annualRuleNote;
    @Input() totalSalesAmount;
    @Input() manualOverrideEnabled;
    @Input() regionName;
    @Input() selectedBonusTerritory;
    @Input() selectedBonusRegion;
    @Input() territoryBonusCustomOption;
    @Input() selectedBonusManufacturer;
    @Input() manufacturerBonusCustomOption;
    @Input() selectedBonusHospital;
    @Input() hospitalBonusCustomOption;
    @Input() selectedBonusSurgeon;
    @Input() surgeonBonusCustomOption;
    @Input() selectedBonusProcedure;
    @Input() procedureBonusCustomOption;
    // @Input() regionTerritory;
    constructor(
        private spinner: NgxSpinnerService,
        private _helper: HelperService,
        private _salespeopleHelper: SalespeopleHelper,
        public activeModal: NgbActiveModal) { }
    ngOnInit() {  
        this.isRegionOptionUsed = (this.selectedBonusRegion) ? true : false;        
        this.manualOverrideEnabled = (this.manualOverrideEnabled) ? true : false;
    }

    updateRule() {
        const isRuleValid = this.performRuleValidation();
        if (isRuleValid) {
            // Hospital
            this.editBonusRuleResponse['hospital'] = (this.selectedBonusHospital) ? this.selectedBonusHospital : null;
            this.editBonusRuleResponse['hospitalBonusCustomOption'] = (this.hospitalBonusCustomOption) ? this.hospitalBonusCustomOption.trim() : '';
            // Manufacturer
            this.editBonusRuleResponse['manufacturer'] = this.selectedBonusManufacturer;
            this.editBonusRuleResponse['manufacturerBonusCustomOption'] = (this.manufacturerBonusCustomOption) ? this.manufacturerBonusCustomOption.trim() : '';
            // Surgeon
            this.editBonusRuleResponse['surgeon'] = (this.selectedBonusSurgeon) ? this.selectedBonusSurgeon : null;
            this.editBonusRuleResponse['surgeonBonusCustomOption'] = (this.surgeonBonusCustomOption) ? this.surgeonBonusCustomOption.trim() : '';
            // Procedure
            this.editBonusRuleResponse['procedure'] = (this.selectedBonusProcedure) ? this.selectedBonusProcedure : null;
            this.editBonusRuleResponse['procedureBonusCustomOption'] = (this.procedureBonusCustomOption) ? this.procedureBonusCustomOption.trim() : '';
            // Territory
            this.editBonusRuleResponse['territory'] = this.selectedBonusTerritory;
            // start RAR-11
            // this.editRuleResponse['regionTerritory'] = this.regionTerritory;
            this.editBonusRuleResponse['regionId'] = this.selectedBonusRegion;
            if(this.selectedBonusRegion){
                this.editBonusRuleResponse['regionName'] = this.regions.find(region => region.id === this.selectedBonusRegion).regionName;
                
            }
            
            // End RAR-11
            this.editBonusRuleResponse['territoryBonusCustomOption'] = (this.territoryBonusCustomOption) ? this.territoryBonusCustomOption.trim() : '';

            this.editBonusRuleResponse['growthBonus'] = this.growthBonus;
            this.editBonusRuleResponse['annualRuleNote'] = this.annualRuleNote;
            this.editBonusRuleResponse['totalSalesAmount'] = this.totalSalesAmount;
            this.editBonusRuleResponse['manualOverrideEnabled'] = this.manualOverrideEnabled;
            this.activeModal.close(this.editBonusRuleResponse);
        }
    }
    performRuleValidation() {
        let isBonusRuleValid = true;
        this.bonusRulesValidationList = { manufacturers: '', territories: '', regions: '', growthBonus: '', procedures: '', surgeons: '', hospitals: '',manualOverride:'' };
        // Manufacturer validation
        const {
          manufacturerIsRuleValid,
          manufacturerRulesValidationList,
          manufacturerCustomOption
        } = this._salespeopleHelper.validateManufacturerWithRequired(
          {
            selectedManufacturer: this.selectedBonusManufacturer,
            rulesValidationList: this.bonusRulesValidationList,
            manufacturerCustomOption: this.manufacturerBonusCustomOption,
            isRuleValid: isBonusRuleValid,
            manufacturers: this.manufacturers
          }
        );
        isBonusRuleValid = manufacturerIsRuleValid;
        this.bonusRulesValidationList = manufacturerRulesValidationList;
        this.manufacturerBonusCustomOption = manufacturerCustomOption;
        // Manufacturer validation ends
        // Territory validation
        if(!this.isRegionOptionUsed) {
          const {
            territoryIsRuleValid,
            territoryRulesValidationList,
            territoryCustomOption
          } = this._salespeopleHelper.validateTerritory(
            {
              selectedTerritory: this.selectedBonusTerritory,
              rulesValidationList: this.bonusRulesValidationList,
              territoryCustomOption: this.territoryBonusCustomOption,
              isRuleValid: isBonusRuleValid,
              territories: this.territories
            }
          );
          isBonusRuleValid = territoryIsRuleValid;
          this.bonusRulesValidationList = territoryRulesValidationList;
          this.territoryBonusCustomOption = territoryCustomOption;
        }
        // Territory validation ends
        // start RAR-11
        if (!this.selectedBonusRegion && !this.selectedBonusTerritory) {
            isBonusRuleValid = false;
            this.bonusRulesValidationList.regions = 'This field is required!';
        }
        // end RAR-11
        // Procedure validation
        const {
          procedureIsRuleValid,
          procedureRulesValidationList,
          procedureCustomOption
        } = this._salespeopleHelper.validateProcedure(
          {
            selectedProcedure: this.selectedBonusProcedure,
            rulesValidationList: this.bonusRulesValidationList,
            procedureCustomOption: this.procedureBonusCustomOption,
            isRuleValid: isBonusRuleValid,
            procedures: this.procedures
          }
        );
        isBonusRuleValid = procedureIsRuleValid;
        this.bonusRulesValidationList = procedureRulesValidationList;
        this.procedureBonusCustomOption = procedureCustomOption;  
        // Procedure validation ends
        // Hospital validation
        const {
          hospitalIsRuleValid,
          hospitalRulesValidationList,
          hospitalCustomOption
        } = this._salespeopleHelper.validateHospital(
            {
              selectedHospital: this.selectedBonusHospital,
              rulesValidationList: this.bonusRulesValidationList,
              hospitalCustomOption: this.hospitalBonusCustomOption,
              isRuleValid: isBonusRuleValid,
              hospitals: this.hospitals
            }
          );
          isBonusRuleValid = hospitalIsRuleValid;
          this.bonusRulesValidationList = hospitalRulesValidationList;
          this.hospitalBonusCustomOption = hospitalCustomOption;
        // Hospital validation ends
        // Surgeon validation
        const {
          surgeonIsRuleValid,
          surgeonRulesValidationList,
          surgeonCustomOption
        } = this._salespeopleHelper.validateSurgeon(
          {
            selectedSurgeon: this.selectedBonusSurgeon,
            rulesValidationList: this.bonusRulesValidationList,
            surgeonCustomOption: this.surgeonBonusCustomOption,
            isRuleValid: isBonusRuleValid,
            surgeons: this.surgeons 
          }
        );
        isBonusRuleValid = surgeonIsRuleValid;
        this.bonusRulesValidationList = surgeonRulesValidationList;
        this.surgeonBonusCustomOption = surgeonCustomOption;
        // Surgeon validation ends
        if (this.growthBonus === '') {
            isBonusRuleValid = false;
            this.bonusRulesValidationList.growthBonus = 'This field is required!';
        } else if (!String(this.growthBonus).match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
            isBonusRuleValid = false;
            this.bonusRulesValidationList.growthBonus = 'Only number allowed';
        } else {
            this.bonusRulesValidationList.growthBonus = '';
        }
        if(this.manualOverrideEnabled && !this.totalSalesAmount) {
            isBonusRuleValid = false;
            this.bonusRulesValidationList.manualOverride = 'This field is required!';
          } else {
            if(this.totalSalesAmount && !String(this.totalSalesAmount).match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
              isBonusRuleValid = false;
              this.bonusRulesValidationList.manualOverride = 'Only number allowed';
            }
          }
        return isBonusRuleValid;
    }
    closePopup() {
        this.activeModal.close({});
    }
    manualOverrideChange(ob: MatSlideToggleChange) {            
        this.manualOverrideEnabled = ob.checked;
        this.performRuleValidation();
      }
    
}
