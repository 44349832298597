import { Component, ViewEncapsulation, Input, OnInit, ViewChild, Output } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-commissiondetail-screen',
    templateUrl: './commissiondetail.screen.component.html',
    styleUrls: ['./commissiondetail.screen.component.scss'],
})

export class CommissionDetailScreenComponent implements OnInit {
    @Input() rule;
    @Input() ruleIndex;
    selectedMonthYear: string;
    constructor(
        private spinner: NgxSpinnerService,
        public activeModal: NgbActiveModal) {
    }
    ngOnInit() {
        this.selectedMonthYear = localStorage.getItem('selectedMonthYearLocalStorage');
    }
    closePopup() {
        this.activeModal.close({});
    }
}
