import { Component, ViewEncapsulation, Input, OnInit, ViewChild } from '@angular/core';
import { RegionsService } from '../regions.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { FlashMessagesService } from 'angular2-flash-messages';

@Component({
  selector: 'app-manageregion-screen',
  templateUrl: './manageregion.screen.component.html',
  styleUrls: ['./manageregion.screen.component.scss'],
  providers: [
    RegionsService
  ]
})

export class ManageRegionScreenComponent implements OnInit {
  @ViewChild('f', { static: false }) floatingLabelForm: NgForm;
  isValid: boolean = true;
  formValidationList: any = {
    regionName: '',
  }
  headerText: string;
  showDeletedRecords: boolean = false;
  @Input() actionMode;
  @Input() regionName;
  @Input() id;
  constructor(
    private _regionService: RegionsService,
    private _router: Router,
    private spinner: NgxSpinnerService,
    private _flashMessagesService: FlashMessagesService,
    public activeModal: NgbActiveModal) {
  }
  ngOnInit() {
    if (this.actionMode === 'add') {
      this.headerText = 'Add Region';
    } else {
      this.headerText = 'Edit Region';
    }
  }

  performRegionValidation() {
    this.formValidationList.regionName = '';
    if (!this.regionName) {
      this.formValidationList.regionName = 'This field is required!';
      this.isValid = false;
    }
    this._regionService.getRegionsList(this.showDeletedRecords).subscribe(
      (regionsData) => {
        regionsData.data.regionData.forEach(async (regions) => {
          if (this.actionMode === 'add') {
            if (regions.regionName === this.regionName) {
              
              this.formValidationList.regionName = 'Region name already exist.';
              this.spinner.hide();
              this.isValid = false;
            }
          } else {
            if (regions.id !== this.id && regions.regionName === this.regionName) {
              this.formValidationList.regionName = 'Region name already exist.';
              this.spinner.hide();
              this.isValid = false;
            }
          }
        });
      },
      error => console.log(error)
    )
    console.log(this.isValid);
  }
  // validateForm() {
  //    let isValid = true;
  //    this.formValidationList.manufacturers = "";
  //    this.formValidationList.addNewManufacturer = "";
  //    if (!this.regionName || this.regionName.trim() === '') {
  //       this.formValidationList.regionName = 'This field is required!';
  //       isValid = false;
  //    }
  //    return isValid;
  // }
  submitForm() {
    this.performRegionValidation();
    if (this.isValid) {
      this.spinner.show();
      if (this.actionMode === 'add') {
        const params = {
          'regionName': this.regionName.trim(),
        };
        this._regionService.addRegion(params).subscribe(
          (data) => {
            this._flashMessagesService.show(data.message, { cssClass: 'alert-success', timeout: 4000 });
            this.spinner.hide();
            this.closePopup();
          },
          (error) => {
            this.spinner.hide();
            this.closePopup();
          }
        )
      } else {
        const params = {
          'regionName': this.regionName.trim(),
          'id': this.id,
        };
        this._regionService.updateRegion(params).subscribe(
          (data) => {
            this._flashMessagesService.show(data.message, { cssClass: 'alert-success', timeout: 4000 });
            this.spinner.hide();
            this.closePopup();
          },
          (error) => {
            this.spinner.hide();
            this.closePopup();
          }
        )
      }

    }
  }
  closePopup() {
    this.activeModal.close('Close click');
  }
}
