import { Component, ViewEncapsulation, Input, OnInit, ViewChild, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HelperService } from '../../core/helper';
import { SalespeopleHelper } from '../salespeople.helper';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-editsadjustment-screen',
  templateUrl: './editadjustment.screen.component.html',
  styleUrls: ['./editadjustment.screen.component.scss'],
  providers: [
    HelperService,
    SalespeopleHelper,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class EditSAdjustmentScreenComponent implements OnInit {
  editAdjustmnetResponse: any = {};
  adjustmentsValidationList: any;
  adjustmentNoneChecked: boolean;
  adjustmentEndMonthMinLimit: any;
  @Input() adjustmentType;
  @Input() adjustmentAmount;
  @Input() adjustmentNote;
  @Input() adjustmentDescription;
  @Input() manufacturers;
  @Input() selectedManufacturerAdjustment;
  @Input() adjustmentManufacturerCustomOption;
  @Input() adjustmentStartMonthYear;
  @Input() adjustmentEndMonthYear;
  adjustmentStartMonthYearEle = new FormControl(moment());
  adjustmentEndMonthYearEle = new FormControl(moment());
  constructor(
    public activeModal: NgbActiveModal,
    private _helper: HelperService,
    private _salespeopleHelper: SalespeopleHelper,
  ) { }
  ngOnInit() {
    this.adjustmentsValidationList = { manufacturers: "", adjustmentType: "", adjustmentAmount: "", adjustmentDescription: "" }
    this.adjustmentStartMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.adjustmentStartMonthYear));
    this.adjustmentEndMonthMinLimit = this._helper.getFormattedMonthYear(this.adjustmentStartMonthYear);
    if (this.adjustmentEndMonthYear != 'None') {
      this.adjustmentEndMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.adjustmentEndMonthYear));
      this.adjustmentNoneChecked = false;
    } else {
      this.adjustmentNoneChecked = true;
    }
  }
  updateAdjustment() {
    const isAdjustmentValid = this.performAdjustmentValidation();
    if (isAdjustmentValid) {
      this.editAdjustmnetResponse["adjustmentType"] = this.adjustmentType;
      this.editAdjustmnetResponse["adjustmentAmount"] = this.adjustmentAmount;
      this.editAdjustmnetResponse["adjustmentDescription"] = this.adjustmentDescription;
      this.editAdjustmnetResponse["adjustmentNote"] = this.adjustmentNote;
      this.editAdjustmnetResponse["selectedManufacturerAdjustment"] = this.selectedManufacturerAdjustment;
      this.editAdjustmnetResponse["adjustmentManufacturerCustomOption"] = (this.adjustmentManufacturerCustomOption) ? this.adjustmentManufacturerCustomOption.trim() : '';
      this.editAdjustmnetResponse["startMonth"] = this.adjustmentStartMonthYear;
      this.editAdjustmnetResponse["endMonth"] = this.adjustmentEndMonthYear;
      this.activeModal.close(this.editAdjustmnetResponse);
    }
  }

  closePopup() {
    this.activeModal.close({});
  }
  performAdjustmentValidation() {
    this.adjustmentsValidationList = { manufacturers: "", adjustmentAmount: "", adjustmentDescription: "" };
    let isAdjustmentValid = true;
    if (this.adjustmentAmount === "") {
      isAdjustmentValid = false;
      this.adjustmentsValidationList.adjustmentAmount = "This field is required!";
    } else if (!this.adjustmentAmount.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      isAdjustmentValid = false;
      this.adjustmentsValidationList.adjustmentAmount = "Only number allowed";
    }
    if (!this.adjustmentDescription) {
      isAdjustmentValid = false;
      this.adjustmentsValidationList.adjustmentDescription = "This field is required!";
    }
    // Manufacturer validation
    const {
      manufacturerIsValid,
      manufacturerValidationList,
      manufacturerCustomOption
    } = this._salespeopleHelper.validateManufacturerWithoutRequired(
      {
        selectedManufacturer: this.selectedManufacturerAdjustment,
        validationList: this.adjustmentsValidationList,
        manufacturerCustomOption: this.adjustmentManufacturerCustomOption,
        isValid: isAdjustmentValid,
        manufacturers: this.manufacturers 
      }
    );
    isAdjustmentValid = manufacturerIsValid;
    this.adjustmentsValidationList = manufacturerValidationList;
    this.adjustmentManufacturerCustomOption = manufacturerCustomOption;
    // Manufacturer validation ends
    return isAdjustmentValid;
  }
  chosenAdjustmentStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.adjustmentStartMonthYear = moment(normalizedMonth).format("MMMM-YYYY");
    this.adjustmentStartMonthYearEle.setValue(moment(normalizedMonth));
    datepicker.close();
  }
  chosenAdjustmentEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.adjustmentEndMonthYear = moment(normalizedMonth).format("MMMM-YYYY");
    this.adjustmentEndMonthYearEle.setValue(moment(normalizedMonth));
    datepicker.close();
  }
  onAdjustmentNoneChange(ob: MatSlideToggleChange) {
    if (ob.checked) {
      this.adjustmentEndMonthYear = 'None';
    } else {
      this.adjustmentEndMonthYear = this.adjustmentStartMonthYear;
      this.adjustmentEndMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.adjustmentStartMonthYear));
      this.adjustmentEndMonthMinLimit = this._helper.getFormattedMonthYear(this.adjustmentStartMonthYear);
    }
    this.adjustmentNoneChecked = ob.checked;
  }
}
