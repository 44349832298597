import { Component, ViewEncapsulation, Input, OnInit, ViewChild } from '@angular/core';
import { UploadFileService } from '../uploadfile.service';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HelperService } from '../../core/helper';
@Component({
   selector: 'app-uploadnewfile-screen',
   templateUrl: './uploadnewfile.screen.component.html',
   styleUrls: ['./uploadnewfile.screen.component.scss'],
   providers: [
      UploadFileService
   ]
})

export class UploadNewFileScreenComponent implements OnInit {
   @ViewChild('f', { static: false }) floatingLabelForm: NgForm;
   // @ViewChild('vform', { static: false }) validationForm: FormGroup;
   // regularForm: FormGroup;
   manufacturers: Array<any>;
   selectedManufacturer: Number;
   selectedMonthYear: String;
   note: String;
   formValidationList: any;
   addNewManufacturer: string;


   filetoupload: Array<File>;
   constructor(
      private _uploadFileService: UploadFileService,
      private _router: Router,
      private spinner: NgxSpinnerService,
      private _helper: HelperService,
      public activeModal: NgbActiveModal) {

      this.manufacturers = [];

      this.note = '';
      this.addNewManufacturer = '';
      this.filetoupload = [];
      this.selectedMonthYear = null;
      this.formValidationList = { 'manufacturers': '', 'filetoupload': '', 'addNewManufacturer': '' };
      // this.regularForm = new FormGroup({
      //    'note': new FormControl(null, []),
      //    'filetoupload': new FormControl(null, [Validators.required]),
      //    'manufacturers': new FormControl(0, [Validators.required]),
      // }, { updateOn: "change" });
   }
   ngOnInit() {
      this.selectedMonthYear = localStorage.getItem('selectedMonthYearLocalStorageUpload');
      this.spinner.show();
      this.selectedManufacturer = 0;
      const filter = ['Manufacturer'];
      const manufacturersTemp = [];
      const currentDate = this._helper.getCurrentDate();
      this._uploadFileService.getMasterData(filter).subscribe(
         (data) => {
            for (let loopIndex = 0; loopIndex < data.manufacturerData.length; loopIndex++) {
               if (!data.manufacturerData[loopIndex].deleteEffectiveDate) {
                 manufacturersTemp.push(data.manufacturerData[loopIndex]);
               } else {
                  if (currentDate < data.manufacturerData[loopIndex].deleteEffectiveDate) {
                     manufacturersTemp.push(data.manufacturerData[loopIndex]);
                  }
               }
             }            
            this.manufacturers = manufacturersTemp;
            this.spinner.hide();
         },
         error => console.log(error)
      )
   }
   fileChangeEvent(fileInput: any) {
      this.filetoupload = <Array<File>>fileInput.target.files;
      this.validateForm();
   }
   validateForm() {

      let isValid = true;
      this.formValidationList.manufacturers = "";
      this.formValidationList.addNewManufacturer = "";
      if (this.selectedManufacturer == 0) {
         this.formValidationList.manufacturers = "This field is required!";
         isValid = false;
      } else if (this.selectedManufacturer == -1 && this.addNewManufacturer.trim() == "") {
         this.formValidationList.addNewManufacturer = "This field is required!";
         isValid = false;
      } else if (this.selectedManufacturer > 0) {
         this.addNewManufacturer = '';
      }
      this.formValidationList.filetoupload = "";
      if (this.filetoupload.length == 0) {
         this.formValidationList.filetoupload = "This field is required!";
         isValid = false;
      } else {
         const validFileExtension = ['xlsx', 'csv', 'xls'];
         const fileName = this.filetoupload[0].name;
         const fileExtension = fileName.split('.').pop();
         if (!validFileExtension.includes(fileExtension)) {
            this.formValidationList.filetoupload = "Invalid file extension!";
            isValid = false;
         }
      }
      return isValid;
   }
   submitForm() {
      if (this.validateForm()) {
         this.spinner.show();
         const params = {
            'note': this.note,
            'manufacturerId': this.selectedManufacturer,
            'selectedMonthYear': this.selectedMonthYear,
            'addNewManufacturer': this.addNewManufacturer.trim(),
         };
         this._uploadFileService.submitUploadForm(params, this.filetoupload).then(
            (data) => {
               this.spinner.hide();
               this.closePopup();
            },
            (error) => {
               this.spinner.hide();
               this.closePopup();
            }
         )
      }
   }
   closePopup() {
      this.activeModal.close('Close click');
   }
}
