import { Component, HostBinding } from '@angular/core';
import { HelperService } from '../../core/helper';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    providers: [
        HelperService
    ]
})

export class FooterComponent{    
    //Variables
    currentDate : Date = new Date();
    constructor(
        private _helper: HelperService,
    ){

    };
    ngOnInit() {
        
    }
}
