import { Component, Input, OnInit } from '@angular/core';
import {  NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HelperService } from '../../core/helper';
import { SalespeopleHelper } from '../salespeople.helper';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};
@Component({
    selector: 'app-editrule-screen',
    templateUrl: './editrule.screen.component.html',
    styleUrls: ['./editrule.screen.component.scss'],
    providers: [
        HelperService,
        SalespeopleHelper,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})
export class EditRuleScreenComponent implements OnInit {
    ruleStartMonthYearEle = new FormControl();
    ruleEndMonthYearEle = new FormControl();
    ruleEndMonthMinLimit: any;
    ruleNoneChecked: boolean;
    isRegionOptionUsed: boolean;    
    rulesValidationList: any = {
        manufacturers: '',
        territories: '',
        commission: '',
        procedures: '',
        note: '',
        surgeons: '',
        hospitals: ''
    };
    editRuleResponse: any = {};
    ruleEndMonthList: Array<any> = [];
    @Input() procedures;
    @Input() manufacturers;
    @Input() hospitals;
    @Input() surgeons;
    @Input() territories;
    @Input() regions;
    @Input() commission;
    @Input() note;
    @Input() regionName;
    @Input() selectedTerritory;
    @Input() selectedRegion;
    @Input() territoryCustomOption;
    @Input() selectedManufacturer;
    @Input() manufacturerCustomOption;
    @Input() selectedHospital;
    @Input() hospitalCustomOption;
    @Input() selectedSurgeon;
    @Input() surgeonCustomOption;
    @Input() selectedProcedure;
    @Input() procedureCustomOption;
    @Input() ruleStartMonthList;
    @Input() ruleMonthList;
    @Input() ruleStartMonthYear;
    @Input() ruleEndMonthYear;    
    // @Input() regionTerritory;
    constructor(
        private spinner: NgxSpinnerService,
        private _helper: HelperService,
        private _salespeopleHelper: SalespeopleHelper,
        public activeModal: NgbActiveModal) { }
    ngOnInit() {
        this.isRegionOptionUsed = (this.selectedRegion) ? true : false;
        this.ruleStartMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.ruleStartMonthYear));
        this.ruleEndMonthMinLimit = this._helper.getFormattedMonthYear(this.ruleStartMonthYear);
        if (this.ruleEndMonthYear !== 'None') {
            this.ruleEndMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.ruleEndMonthYear));
            this.ruleNoneChecked = false;
        } else {
            this.ruleNoneChecked = true;
        }
    }

    updateRule() {console.log(this.note);
        const isRuleValid = this.performRuleValidation();
        if (isRuleValid) {
            // Hospital
            this.editRuleResponse['hospital'] = (this.selectedHospital) ? this.selectedHospital : null;
            this.editRuleResponse['hospitalCustomOption'] = (this.hospitalCustomOption) ? this.hospitalCustomOption.trim() : '';
            // Manufacturer
            this.editRuleResponse['manufacturer'] = this.selectedManufacturer;
            this.editRuleResponse['manufacturerCustomOption'] = (this.manufacturerCustomOption) ? this.manufacturerCustomOption.trim() : '';
            // Surgeon
            this.editRuleResponse['surgeon'] = (this.selectedSurgeon) ? this.selectedSurgeon : null;
            this.editRuleResponse['surgeonCustomOption'] = (this.surgeonCustomOption) ? this.surgeonCustomOption.trim() : '';
            // Procedure
            this.editRuleResponse['procedure'] = (this.selectedProcedure) ? this.selectedProcedure : null;
            this.editRuleResponse['procedureCustomOption'] = (this.procedureCustomOption) ? this.procedureCustomOption.trim() : '';
            // Territory
            this.editRuleResponse['territory'] = this.selectedTerritory;
            // start RAR-11
            // this.editRuleResponse['regionTerritory'] = this.regionTerritory;
            this.editRuleResponse['regionId'] = this.selectedRegion;
            if(this.selectedRegion){
                this.editRuleResponse['regionName'] = this.regions.find(region => region.id === this.selectedRegion).regionName;
                console.log(this.editRuleResponse['regionName']);
            }
            
            // End RAR-11
            this.editRuleResponse['territoryCustomOption'] = (this.territoryCustomOption) ? this.territoryCustomOption.trim() : '';

            this.editRuleResponse['commission'] = this.commission;
            this.editRuleResponse['note'] = this.note;
            this.editRuleResponse['startMonth'] = this.ruleStartMonthYear;
            this.editRuleResponse['endMonth'] = this.ruleEndMonthYear;
            this.activeModal.close(this.editRuleResponse);
        }
    }
    performRuleValidation() {
        let isRuleValid = true;
        this.rulesValidationList = { manufacturers: '', territories: '', regions: '', commission: '', procedures: '', surgeons: '', hospitals: '' };
        // Manufacturer validation
        const {
            manufacturerIsRuleValid,
            manufacturerRulesValidationList,
            manufacturerCustomOption
        } = this._salespeopleHelper.validateManufacturerWithRequired(
            {
                selectedManufacturer: this.selectedManufacturer,
                rulesValidationList: this.rulesValidationList,
                manufacturerCustomOption: this.manufacturerCustomOption,
                isRuleValid,
                manufacturers: this.manufacturers
            }
        );
        isRuleValid = manufacturerIsRuleValid;
        console.log(manufacturerRulesValidationList);
        this.rulesValidationList = manufacturerRulesValidationList;
        this.manufacturerCustomOption = manufacturerCustomOption; 
        // Manufacturer validation ends
        // Territory validation
        const {
            territoryIsRuleValid,
            territoryRulesValidationList,
            territoryCustomOption
        } = this._salespeopleHelper.validateTerritory(
            {
                selectedTerritory: this.selectedTerritory,
                selectedContent: this.selectedRegion,
                rulesValidationList: this.rulesValidationList,
                territoryCustomOption: this.territoryCustomOption,
                isRuleValid,
                territories: this.territories
            }
        );
        isRuleValid = territoryIsRuleValid;
        this.rulesValidationList = territoryRulesValidationList;
        this.territoryCustomOption = territoryCustomOption;
        // Territory validation ends
        // start RAR-11
        if (!this.selectedRegion && !this.selectedTerritory) {
            isRuleValid = false;
            this.rulesValidationList.regions = 'This field is required!';
        }
        // end RAR-11
        // Procedure validation
        const {
            procedureIsRuleValid,
            procedureRulesValidationList,
            procedureCustomOption
        } = this._salespeopleHelper.validateProcedure(
            {
                selectedProcedure: this.selectedProcedure,
                rulesValidationList: this.rulesValidationList,
                procedureCustomOption: this.procedureCustomOption,
                isRuleValid,
                procedures: this.procedures,
            }
        );
        isRuleValid = procedureIsRuleValid;
        this.rulesValidationList = procedureRulesValidationList;
        this.procedureCustomOption = procedureCustomOption;
        // Procedure validation ends
        // Hospital validation
        const {
            hospitalIsRuleValid,
            hospitalRulesValidationList,
            hospitalCustomOption
        } = this._salespeopleHelper.validateHospital(
            {
                selectedHospital: this.selectedHospital,
                rulesValidationList: this.rulesValidationList,
                hospitalCustomOption: this.hospitalCustomOption,
                isRuleValid,
                hospitals: this.hospitals,
            }
        );
        isRuleValid = hospitalIsRuleValid;
        this.rulesValidationList = hospitalRulesValidationList;
        this.hospitalCustomOption = hospitalCustomOption;
        // Hospital validation ends 
        // Surgeon validation
        const {
          surgeonIsRuleValid,
          surgeonRulesValidationList,
          surgeonCustomOption
        } = this._salespeopleHelper.validateSurgeon(
          {
            selectedSurgeon: this.selectedSurgeon,
            rulesValidationList: this.rulesValidationList,
            surgeonCustomOption: this.surgeonCustomOption,
            isRuleValid,
            surgeons: this.surgeons    
          }
        );
        isRuleValid = surgeonIsRuleValid;
        this.rulesValidationList = surgeonRulesValidationList;
        this.surgeonCustomOption = surgeonCustomOption;
        // Surgeon validation ends
        if (this.commission === '') {
            isRuleValid = false;
            this.rulesValidationList.commission = 'This field is required!';
        } else if (!String(this.commission).match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
            isRuleValid = false;
            this.rulesValidationList.commission = 'Only number allowed';
        } else {
            this.rulesValidationList.commission = '';
        }
        return isRuleValid;
    }
    closePopup() {
        this.activeModal.close({});
    }
    chosenRuleStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        this.ruleStartMonthYear = moment(normalizedMonth).format('MMMM-YYYY');
        this.ruleStartMonthYearEle.setValue(moment(normalizedMonth));
        datepicker.close();
    }
    chosenRuleEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        this.ruleEndMonthYear = moment(normalizedMonth).format('MMMM-YYYY');
        this.ruleEndMonthYearEle.setValue(moment(normalizedMonth));
        datepicker.close();
    }
    onRuleNoneChange(ob: MatSlideToggleChange) {
        if (ob.checked) {
            this.ruleEndMonthYear = 'None';
        } else {
            this.ruleEndMonthYear = this.ruleStartMonthYear;
            this.ruleEndMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.ruleStartMonthYear));
            this.ruleEndMonthMinLimit = this._helper.getFormattedMonthYear(this.ruleStartMonthYear);
        }
        this.ruleNoneChecked = ob.checked;
    }
}
