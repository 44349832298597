import { Component, ViewEncapsulation, Input, OnInit, ViewChild } from '@angular/core';
import { TerritoriesService } from '../territories.service';
import { HelperService } from '../../core/helper';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { FlashMessagesService } from 'angular2-flash-messages';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
	parse: {
		dateInput: 'MM/YYYY',
	},
	display: {
		dateInput: 'MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY',
	},
};

@Component({
	selector: 'app-assignregion-screen',
	templateUrl: './assignregion.screen.component.html',
	styleUrls: ['./assignregion.screen.component.scss'],
	providers: [
		TerritoriesService,
		HelperService,
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
	]
})

export class AssignRegionScreenComponent implements OnInit {
	@ViewChild('f', { static: false }) floatingLabelForm: NgForm;
	@Input() territory;
	selectedMonthYear: string = ""
	selectedMonthYearEle = new FormControl();
	monthYearMinLimit: any;
	selectedAssignRegion: string = null;
	// regionValidationList: any;
	territoryName: string;
	assignRegions: Array<any> = [];
	regionValidationList: any = {
		assignRegions: '',
	}

	constructor(
		private _territoriesService: TerritoriesService,
		private _router: Router,
		private spinner: NgxSpinnerService,
		private _helper: HelperService,
		private _flashMessagesService: FlashMessagesService,
		public activeModal: NgbActiveModal) {
	}
	ngOnInit() {
		this.territoryName = this.territory.territoryName;
		this._territoriesService.getRegions().subscribe(
			(regionListData) => {
				this.assignRegions = regionListData.data.regionsData;
			},
			error => console.log(error)
		)
	}

	closePopup() {
		this.activeModal.close('Close click');
	}
	saveRegion() {
		const isRegionValid = this.performRegionValidation();
		if (isRegionValid) {
			const params = {
				'regionId': this.selectedAssignRegion,
				'territoryId': this.territory.id
			};
			this._territoriesService.assignRegion(params).subscribe((regionData) => {
				if (regionData.status === 'success') {
					this._flashMessagesService.show(regionData.message, { cssClass: 'alert-success', timeout: 4000 });
				} else {
					this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger', timeout: 4000 });
				}
				this.spinner.hide();
				this.activeModal.close({});
			},
				(error) => {
					this.spinner.hide();
					this.activeModal.close({});
				}
			)
		}
	}
	performRegionValidation() {
		let isRegionValid = true;
		this.regionValidationList = { assignRegions: '' };
		if (this.selectedAssignRegion === null) {
			isRegionValid = false;
			this.regionValidationList.assignRegions = 'This field is required!';
		}
		return isRegionValid;
	}
}
