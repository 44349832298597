import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from '../user.service';
import { FlashMessagesService } from 'angular2-flash-messages';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-editprofile-screen',
  templateUrl: './editprofile.screen.component.html',
  styleUrls: ['./editprofile.screen.component.scss'],
  providers: [UserService],
})

export class EditProfileScreenComponent implements OnInit {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  confirmPassword: string;
  formValidationList: any = { 'firstName': '', 'lastName': '', 'phone': '', email: '', emailVerification: '', phoneVerification: '' };
  oldEmail: string = '';
  oldPassword: string;
  oldPhone: string;
  isTwoFaEnabled: boolean = false;
  showResendButton: boolean = false;
  isEmailVerificationRequired: boolean = false;
  isPhoneVerificationRequired: boolean = false;
  isEmailVerified: boolean = true;
  isPhoneVerified: boolean = true;
  smsOptIn: boolean
  constructor(
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private _userService: UserService,
    private _flashMessagesService: FlashMessagesService
  ) {
  }
  ngOnInit() {
    this._userService.getMe().subscribe(
      (meData) => {
        this.firstName = meData.data.firstName;
        this.lastName = meData.data.lastName;
        this.oldPhone = this.phone = meData.data.phone;
        this.oldEmail = this.email = meData.data.email;
        this.isTwoFaEnabled = meData.data.isTwoFaEnabled;
        this.smsOptIn = meData.data.smsOptIn;
        /* if(meData.data.isPhoneVerificationRequired === 1) {
          this.isPhoneVerificationRequired = meData.data.isPhoneVerificationRequired;
          this.isPhoneVerified = false;
        } */
      });
  }

  updateProfile() {
    if (this.validateForm()) {
      this.spinner.show();      
      if(this.password !== undefined && this.password.trim() !== "") {
        // Password change case.
        this._userService.checkUserSession().then(() => {
          this._userService.changePasswordInCognito({ 
            oldPassword: this.oldPassword,
            password:this.password
          })
          .then(() => {
            this.updateUserData();
          })
          .catch((error) => {
            console.log(error);
            this._flashMessagesService.show(error, { cssClass: 'alert-danger', timeout: 4000 });
            this.spinner.hide();
          })
        })        
      } else {
        this.updateUserData();
      }            
    }
  }
  verifyEmail() {
    if(this.validateEmail()) {
      this.updateEmailAddressInCognitoCase();
    }
  }
  verifyPhone() {
    if(this.validatePhone()) {
      this.updatePhoneInCognitoCase();
    }
  }
  resendVerificationCode() {
    this.updateEmailAddressInCognitoCase();
  }
  updateEmailAddressInCognitoCase() {
    this._userService.checkUserSession().then(() => {
      this._userService.updateEmailAttributeInCognito({
        newEmail: this.email,
        spinnerObj: this.spinner,
        flashMessagesServiceObj: this._flashMessagesService,
      })
      .then(() => {
        console.log('then -> email');
        // this.updateUserData();
        this.isEmailVerified = true;
        this.isEmailVerificationRequired = false;
        this.updateEmail();
        this.validateForm();
      })
      .catch((error) => {
        this._flashMessagesService.show(error, { cssClass: 'alert-danger', timeout: 4000 });          
        this.spinner.hide();
        // this.showResendButton = true;
      });
    });
    
  }
  updatePhoneInCognitoCase() {
    const phoneRegExp = /[\(\)\s-]/g;
    this._userService.checkUserSession().then(() => {
      this._userService.updatePhoneAttributeInCognito({
        newPhone: `${this.phone.replace(phoneRegExp, '')}`,
        spinnerObj: this.spinner,
        flashMessagesServiceObj: this._flashMessagesService,
      })
      .then(() => {
        this.isPhoneVerified = true;
        this.isPhoneVerificationRequired = false;
        this.updatePhone();
        this.validateForm();
      })
      .catch((error) => {
        this._flashMessagesService.show(error, { cssClass: 'alert-danger', timeout: 4000 });          
        this.spinner.hide();
        this.showResendButton = true;
      });
    });
  }
  updatePhone() {
    const params = {
      'phone': this.phone,
      'userSelectedRecordId': atob(localStorage.getItem('loginToken')),
    };
    this._userService.updateUserPhone(params).subscribe(
      () => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this._flashMessagesService.show(error.message, { cssClass: 'alert-danger', timeout: 4000 });
      })
  }
  updateEmail() {
    const params = {
      'email': this.email,
      'userSelectedRecordId': atob(localStorage.getItem('loginToken')),
    };
    this._userService.updateUserEmail(params).subscribe(
      () => {
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this._flashMessagesService.show(error.message, { cssClass: 'alert-danger', timeout: 4000 });
      })
  }
  updateUserData() {
    const params = {
      'firstName': this.firstName,
      'lastName': this.lastName,
      'phone': this.phone,
      'userSelectedRecordId': atob(localStorage.getItem('loginToken')),
      'email': this.email,
      'requestFrom': 'updateprofile',
      'isTwoFaEnabled': this.isTwoFaEnabled,
    };
    this._userService.updateUserData(params).subscribe(
      (profileData) => {
        this.spinner.hide();
        if (profileData.status === 'success') {
          this._flashMessagesService.show(profileData.message, { cssClass: 'alert-success', timeout: 4000 });
        } else {
          this._flashMessagesService.show('Something went wrong!', { cssClass: 'alert-danger', timeout: 4000 });
        }
        this.activeModal.close({});
      },
      (error) => {
        this.spinner.hide();
        this.activeModal.close({});
        this._flashMessagesService.show(error.message, { cssClass: 'alert-danger', timeout: 4000 });
      }
    )
  }
  validateForm() {
    let isValid = true;
    this.formValidationList = { 'firstName': '', 'lastName': '', 'phone': '' };
    if (!this.firstName) {
      this.formValidationList.firstName = "This field is required!";
      isValid = false;
    }
    if (!this.lastName) {
      this.formValidationList.lastName = "This field is required!";
      isValid = false;
    }    
    // isValid = this.validateEmail(isValid, eventType);
    // isValid = this.validatePhone(isValid, eventType);
    if(this.isEmailVerificationRequired && !this.isEmailVerified) {
      isValid = false;
      this.formValidationList.email = "Email verification is required!";
    }
    if(this.isPhoneVerificationRequired && !this.isPhoneVerified) {
      isValid = false;
      this.formValidationList.phone = "Phone verification is required!";
    }
    if(this.oldPassword !== undefined) {
      if (!(this.oldPassword.match(/[a-z]/g) && this.oldPassword.match(/[A-Z]/g) && this.oldPassword.match(/[0-9]/g)
        && this.oldPassword.match(/[^a-zA-Z\d]/g) && this.oldPassword.length >= 8)) {
          this.formValidationList.oldPassword = 'Password should contain at least one uppercase character, at least one lowercase character, at least one digit, at least one special character and should be of minimum 8 characters long!';
          isValid = false;
      }
    }
    if (this.password !== undefined) {
      if (!(this.password.match(/[a-z]/g) && this.password.match(/[A-Z]/g) && this.password.match(/[0-9]/g)
        && this.password.match(/[^a-zA-Z\d]/g) && this.password.length >= 8)
      ) {
        this.formValidationList.password = 'Password should contain at least one uppercase character, at least one lowercase character, at least one digit, at least one special character and should be of minimum 8 characters long!';
        isValid = false;
      } else {
        if(this.oldPassword === undefined || this.oldPassword == '') {
          this.formValidationList.oldPassword = 'This field is required!';
          isValid = false;
        } else if (this.confirmPassword == '') {
          this.formValidationList.confirmPassword = 'This field is required!';
          isValid = false;
        } else {
          if (!(this.confirmPassword.match(/[a-z]/g) && this.confirmPassword.match(/[A-Z]/g) && this.confirmPassword.match(/[0-9]/g)
            && this.confirmPassword.match(/[^a-zA-Z\d]/g) && this.confirmPassword.length >= 8)
          ) {
            this.formValidationList.confirmPassword = "Confirm password should contain at least one uppercase character, at least one lowercase character, at least one digit, at least one special character and should be of minimum 8 characters long!";
            isValid = false;
          } else if (this.confirmPassword != this.password) {
            this.formValidationList.confirmPassword = "Password and Confirm password should be same!";
            isValid = false;
          }
        }
      }
    }
    return isValid;
  }
  validatePhone() {
    let isValid = true;
    if (this.phone === undefined) {
      this.formValidationList.phone = "This field is required!";
      isValid = false;
    } else if (!/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
      .test(this.phone)) {
      this.formValidationList.phone = 'You have entered an invalid phone number!';
      isValid = false;
    } else {
      if(this.oldPhone !== this.phone) {
        this.isPhoneVerified = false;
        this.isPhoneVerificationRequired = true;
      } else {
        this.isPhoneVerified = true;
        this.isPhoneVerificationRequired = false;
      }
    }
    return isValid;
  }
  smsOptInChanged(event) {
    this.spinner.show();
    console.log(this.smsOptIn);
    this._userService.checkUserSession().then(() => {
      console.log(this.smsOptIn);
      this._userService.updateSmsOptInAttributeInCognito({
        smsOptIn: this.smsOptIn,
        spinnerObj: this.spinner,
        flashMessagesServiceObj: this._flashMessagesService,
      })
      .then(() => {
        const params = {
          smsOptIn: this.smsOptIn,
          'userSelectedRecordId': atob(localStorage.getItem('loginToken')),
        };
        this._userService.updateSmsOptIn(params).subscribe(
          () => {
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this._flashMessagesService.show(error.message, { cssClass: 'alert-danger', timeout: 4000 });
          })        
      })
      .catch((error) => {
        this._flashMessagesService.show(error, { cssClass: 'alert-danger', timeout: 4000 });          
        this.spinner.hide();
      });
    });
  }
  validateEmail() {
    let isValid = true;
    if (this.email === undefined) {
      this.formValidationList.email = 'This field is required!';
      isValid = false;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
      this.formValidationList.email = 'You have entered an invalid email address!';
      isValid = false;
    } else {
      if(this.oldEmail !== this.email) {
        this.isEmailVerified = false;
        this.isEmailVerificationRequired = true;
      } else {
        this.isEmailVerified = true;
        this.isEmailVerificationRequired = false;
      }
    }
    return isValid;
  }
  emailChanged() {
    console.log('emailChanged');
  }

  closePopup() {
    this.activeModal.close({});
  }
}
