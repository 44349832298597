export class SalespeopleHelper {
  validateTerritory(validationRequest) {
    if (validationRequest.selectedTerritory === null && !validationRequest.selectedContent) {
      validationRequest.isRuleValid = false;
      validationRequest.rulesValidationList.territories = 'This field is required!';
    } else if (validationRequest.selectedTerritory !== null
      && validationRequest.selectedTerritory !== 'Add New'
      && !validationRequest.selectedContent
      && typeof validationRequest.territoryCustomOption !== "undefined"
      && validationRequest.territoryCustomOption !== null
      && validationRequest.territoryCustomOption.trim() !== ''
      ) {
        validationRequest.territoryCustomOption = '';
    } else if (validationRequest.selectedTerritory === 'Add New'
      && (!validationRequest.territoryCustomOption || validationRequest.territoryCustomOption.trim() == '') && !validationRequest.selectedContent) {
      validationRequest.isRuleValid = false;
      validationRequest.rulesValidationList.territories = 'This field is required!';
    } else if (validationRequest.selectedTerritory === 'Add New'
      && typeof validationRequest.territoryCustomOption !== "undefined" 
      && validationRequest.territoryCustomOption.trim() !== '' 
      && !validationRequest.selectedContent
     ) {
      const territoryRecordIndex = validationRequest.territories.findIndex( territory => territory.territoryName === validationRequest.territoryCustomOption.trim());
      if(territoryRecordIndex >= 0) {
        validationRequest.isRuleValid = false;
        validationRequest.rulesValidationList.territories = 'Territory name already exist in list!';
      }
    }
    return {
      territoryIsRuleValid: validationRequest.isRuleValid,
      territoryRulesValidationList:  validationRequest.rulesValidationList,
      territoryCustomOption:validationRequest.territoryCustomOption
    }
  }
  /**
   * Validates manufactuer fields value
   * @param validationRequest
   * @returns object
   */
  validateManufacturerWithRequired(validationRequest) {
    if(validationRequest.selectedManufacturer === null) {
      // When manufacturer option is not selected
      validationRequest.isRuleValid = false;
      validationRequest.rulesValidationList.manufacturers = 'This field is required!';
    } else if(validationRequest.selectedManufacturer !== null && validationRequest.selectedManufacturer === 'Add New') {
      // When add new option selected
        if(typeof validationRequest.manufacturerCustomOption !== "undefined"
          && validationRequest.manufacturerCustomOption !== null
          && validationRequest.manufacturerCustomOption.trim() !== '') {
          // When manufacturer custom value provided
          const manufacturerRecordIndex = validationRequest.manufacturers.findIndex(manufacturer => manufacturer.manufacturerName === validationRequest.manufacturerCustomOption.trim());
          if(manufacturerRecordIndex >= 0) {
            // Validate whether manufacturer name already exist in list or not
            validationRequest.isRuleValid = false;
            validationRequest.rulesValidationList.manufacturers = 'Manufacturer name already exist in list!';
          }
        } else {
          // When there is no custom manufacturer name provided
          validationRequest.isRuleValid = false;
          validationRequest.rulesValidationList.manufacturers = 'This field is required!';
        }

    } else if((validationRequest.selectedManufacturer !== null && validationRequest.selectedManufacturer !== 'Add New')
      && (
        typeof validationRequest.manufacturerCustomOption !== "undefined"
        && validationRequest.manufacturerCustomOption !== null
        && validationRequest.manufacturerCustomOption.trim() !== '')
    ) {
      // When add new option not selected & custom manufacturer textbox has value
      validationRequest.manufacturerCustomOption = '';
    }
    return {
      manufacturerIsRuleValid: validationRequest.isRuleValid,
      manufacturerRulesValidationList:  validationRequest.rulesValidationList,
      manufacturerCustomOption:validationRequest.manufacturerCustomOption
    }
  }
  /**
   * Validates procedure fields value
   * @param validationRequest
   * @returns object
   */
  validateProcedure(validationRequest) {
    if(validationRequest.selectedProcedure !== null && validationRequest.selectedProcedure === 'Add New') {
      // When Add new option selected
      if(typeof validationRequest.procedureCustomOption !== "undefined"
        && validationRequest.procedureCustomOption !== null
        && validationRequest.procedureCustomOption.trim() !== '') {
        // When procedure custom value provided
        const procedureRecordIndex = validationRequest.procedures.findIndex(procedure => procedure.procedureName === validationRequest.procedureCustomOption.trim());
        if(procedureRecordIndex >= 0) {
          // Validate whether procedure name already exist in list or not
          validationRequest.isRuleValid = false;
          validationRequest.rulesValidationList.procedures = 'Procedure name already exist in list!';
        }
      } else {
        // When there is no custom procedure name provided
        validationRequest.isRuleValid = false;
        validationRequest.rulesValidationList.procedures = 'This field is required!';
      }
    } else if((validationRequest.selectedProcedure !== null && validationRequest.selectedProcedure !== 'Add New')
      && (typeof validationRequest.procedureCustomOption !== "undefined"
      && validationRequest.procedureCustomOption !== null
      && validationRequest.procedureCustomOption.trim() !== '')) {
        // When Add New option not selected & custom procedure textbox has value.
        validationRequest.procedureCustomOption = '';  
    }
    return {
      procedureIsRuleValid: validationRequest.isRuleValid,
      procedureRulesValidationList: validationRequest.rulesValidationList,
      procedureCustomOption:validationRequest.procedureCustomOption
    }
  }
  /**
   * Validates procedure fields value
   * @param validationRequest
   * @returns object
   */
  validateHospital(validationRequest) {
    if(validationRequest.selectedHospital !== null && validationRequest.selectedHospital === 'Add New') {
      // When Add new option selected
      if(typeof validationRequest.hospitalCustomOption !== "undefined"
        && validationRequest.hospitalCustomOption !== null
        && validationRequest.hospitalCustomOption.trim() !== '') {
        // When hospital custom value provided
        const hospitalRecordIndex = validationRequest.hospitals.findIndex(hospital => hospital.hospitalName === validationRequest.hospitalCustomOption.trim());
        if(hospitalRecordIndex >= 0) {
          // Validate whether hospital name already exist in list or not
          validationRequest.isRuleValid = false;
          validationRequest.rulesValidationList.hospitals = 'Hospital name already exist in list!';
        }
      } else {
        // When there is no custom hospital name provided
        validationRequest.isRuleValid = false;
        validationRequest.rulesValidationList.hospitals = 'This field is required!';
      }

    } else if((validationRequest.selectedHospital !== null && validationRequest.selectedHospital !== 'Add New')
      && (typeof validationRequest.hospitalCustomOption !== "undefined"
        && validationRequest.hospitalCustomOption !== null
        && validationRequest.hospitalCustomOption.trim() !== '')) {
        // When Add new option selected and custom hospital texbox value
        validationRequest.hospitalCustomOption = '';
    }
    return {
      hospitalIsRuleValid: validationRequest.isRuleValid,
      hospitalRulesValidationList: validationRequest.rulesValidationList,
      hospitalCustomOption:validationRequest.hospitalCustomOption
    }
  }
  /**
   * Validates procedure fields value
   * @param validationRequest
   * @returns object
   */
  validateSurgeon(validationRequest) {
    if(validationRequest.selectedSurgeon !== null && validationRequest.selectedSurgeon === 'Add New') {
      // When add new option selected
      if(typeof validationRequest.surgeonCustomOption !== "undefined"
        && validationRequest.surgeonCustomOption !== null
        && validationRequest.surgeonCustomOption.trim() !== '') {
        // When custom surgeon name provided
        const surgeonRecordIndex = validationRequest.surgeons.findIndex(surgeon => surgeon.fullName === validationRequest.surgeonCustomOption.trim());
        if(surgeonRecordIndex >= 0) {
          // Validate whether surgeon name already exist in list or not
          validationRequest.isRuleValid = false;
          validationRequest.rulesValidationList.surgeons = 'Surgeon name already exist in list!';
        }
      } else {
        // When there is no custom surgeon name provided
        validationRequest.isRuleValid = false;
        validationRequest.rulesValidationList.surgeons = 'This field is required!';  
      }
    } else if((validationRequest.selectedSurgeon !== null && validationRequest.selectedSurgeon !== 'Add New')
    && (typeof validationRequest.surgeonCustomOption !== "undefined"
      && validationRequest.surgeonCustomOption !== null
      && validationRequest.surgeonCustomOption.trim() !== '')) {
      // When Add new option not selected and custom surgeon textbox has value
      validationRequest.surgeonCustomOption = '';
    }
    return {
      surgeonIsRuleValid: validationRequest.isRuleValid,
      surgeonRulesValidationList: validationRequest.rulesValidationList,
      surgeonCustomOption:validationRequest.surgeonCustomOption
    }
  }
  /**
   * Validates manufactuer fields value
   * @param validationRequest
   * @returns object
   */
  validateManufacturerWithoutRequired(validationRequest) {
    if(validationRequest.selectedManufacturer !== null && validationRequest.selectedManufacturer === 'Add New') {
      // When add new option selected
        if(typeof validationRequest.manufacturerCustomOption !== "undefined" 
          && validationRequest.manufacturerCustomOption !== null
          && validationRequest.manufacturerCustomOption.trim() !== '') {
          // When manufacturer custom value provided
          const manufacturerRecordIndex = validationRequest.manufacturers.findIndex(manufacturer => manufacturer.manufacturerName === validationRequest.manufacturerCustomOption.trim());
          if(manufacturerRecordIndex >= 0) {
            // Validate whether manufacturer name already exist in list or not
            validationRequest.isValid = false;
            validationRequest.validationList.manufacturers = 'Manufacturer name already exist in list!';
          }
        } else {
          // When there is no custom manufacturer name provided
          validationRequest.isValid = false;
          validationRequest.validationList.manufacturers = 'This field is required!';
        }

    } else if((validationRequest.selectedManufacturer !== null && validationRequest.selectedManufacturer !== 'Add New')
      && (typeof validationRequest.manufacturerCustomOption !== "undefined"
        && validationRequest.manufacturerCustomOption !== null
        && validationRequest.manufacturerCustomOption.trim() !== '')
    ) {
      // When add new option not selected & custom manufacturer textbox has value
      validationRequest.manufacturerCustomOption = '';
    }
    return {
      manufacturerIsValid: validationRequest.isValid,
      manufacturerValidationList: validationRequest.validationList,
      manufacturerCustomOption:validationRequest.manufacturerCustomOption
    }
  }
}