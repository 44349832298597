export class ConfigService {
  private _environment: string;
  private _apiUrl: String;
  private _accesskeyId: String;
  private _secretAccessKey: String;
  private _uploadBucketName: String;
  constructor() {
    this.setEnvironment();
    this.setApiUrl();
    this.setAccesskeyId();
    this.setSecretAccessKey();
    this.setUploadBucketName();
  }
  setEnvironment() {
    let companyName;
    console.log("V98");
    const browserUrl = window.location.href;
    if (browserUrl.match(/sales.raridon.org/)) {
      this._environment = "production";
      companyName = "raridon";
    } else if (browserUrl.match(/sales-staging.raridon.org/)) {
      this._environment = "staging";
      companyName = "raridon";
    } else if (browserUrl.match(/lcortho-sales.com/)) {
      this._environment = "lcortho-production";
      companyName = "lcortho";
    } else if (browserUrl.match(/a1a-autocomm.com/)) {
      this._environment = "A1A-production";
      companyName = "A1A";
    } else if (browserUrl.match(/amp-autocomm.com/)) {
      this._environment = "AMP-production";
      companyName = "AMP";
    } else if (browserUrl.match(/djd-autocomm.com/)) {
      this._environment = "DJD-production";
      companyName = "DJD";
    } else if (browserUrl.match(/inmotionortho-sales.com/)) {
      this._environment = "inmotionortho-production";
      companyName = "inmotionortho";
    } else if (browserUrl.match(/medicalventures-sales.com/)) {
      this._environment = "medicalventures-production";
      companyName = "medicalventures";
    } else if (browserUrl.match(/simpson-sales.com/)) {
      this._environment = "simpson-production";
      companyName = "simpson";
    } else if (browserUrl.match(/mvisidelines-sales.com/)) {
      this._environment = "mvisidelines-production";
      companyName = "mvisidelines";
    } else if (browserUrl.match(/hallautocomm.com/)) {
      this._environment = "hallautocomm-production";
      companyName = "Hallautocomm";
    } else if (browserUrl.match(/localhost:4200/)) {
      this._environment = "local";
      // In local, replace raridon by whatever environment want to test.
      companyName = "raridon";
    } else {
      alert("environment is not set");
    }
    localStorage.setItem("cn", companyName);
  }
  get environment() {
    return this._environment;
  }

  setApiUrl() {
    if (this.environment == "production") {
      // production variables.
      this._apiUrl = "https://sales-api.raridon.org/api";
    } else if (this.environment == "staging") {
      // staging variables
      this._apiUrl = "https://sales-api-staging.raridon.org/api";
    } else if (this.environment == "lcortho-production") {
      // lcortho production variables
      this._apiUrl = "https://api.lcortho-sales.com/api";
    } else if (this.environment == "A1A-production") {
      // A1A production variables
      this._apiUrl = "https://api.a1a-autocomm.com/api";
    } else if (this.environment == "inmotionortho-production") {
      this._apiUrl = "https://api.inmotionortho-sales.com/api";
    } else if (this.environment == "medicalventures-production") {
      this._apiUrl = "https://api.medicalventures-sales.com/api";
    } else if (this.environment == "simpson-production") {
      this._apiUrl = "https://api.simpson-sales.com/api";
    } else if (this.environment == "mvisidelines-production") {
      this._apiUrl = "https://api.mvisidelines-sales.com/api";
    } else if (this.environment == "DJD-production") {
      // DJD production variables
      this._apiUrl = "https://api.djd-autocomm.com/api";
    } else if (this.environment == "hallautocomm-production") {
      // DJD production variables
      this._apiUrl = "https://api.hallautocomm.com/api";
    } else if (this.environment == "AMP-production") {
      // AMP production variables
      this._apiUrl = "https://api.amp-autocomm.com/api";
    } else {
      // local envrionemtn variables
      this._apiUrl = "http://localhost:3000/api";
      //this._apiUrl = 'http://192.168.1.10:3000/api'
    }
  }
  get apiUrl() {
    return this._apiUrl;
  }
  setAccesskeyId() {
    // production
    if (this.environment == "production") {
      // production variables.
      this._accesskeyId = "AKIAXFQMZTCYWOL56BEJ";
    } else {
      this._accesskeyId = "AKIAXFQMZTCYWOL56BEJ";
    }
  }
  get accesskeyId() {
    return this._accesskeyId;
  }
  setSecretAccessKey() {
    // production
    if (this.environment == "production") {
      // production variables.
      this._secretAccessKey = "Hm4dHTNtrl2F3bZpw3/yoa4rruvgaNleQ8iZMbKI";
    } else {
      this._secretAccessKey = "Hm4dHTNtrl2F3bZpw3/yoa4rruvgaNleQ8iZMbKI";
    }
  }
  get secretAccessKey() {
    return this._secretAccessKey;
  }

  setUploadBucketName() {
    // production
    if (this.environment == "production") {
      // production variables.
      this._uploadBucketName = "uploads.prodraridon.org";
    } else {
      this._uploadBucketName = "uploads.raridon.org";
    }
  }
  get uploadBucketName() {
    return this._uploadBucketName;
  }
}
