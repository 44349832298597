import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from './user/user.service';
import { PlatformLocation } from "@angular/common";
import { DataService } from './core/data';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    providers: [
      UserService
    ]
})
export class AppComponent implements OnInit, OnDestroy {
  loadingText: string;
  subscription: Subscription;
  screenAccess: any = {};
  dashAccess =  false;
  salesRepAccess = false;
  fileUploadAccess = false;
  reportsAccess = false;
  commissionAccess = false;
  constructor(
    private platformLocation: PlatformLocation,
    private _data: DataService
  ){
    this._data.currentText$.subscribe(loadingText => {
      this.loadingText = loadingText;
    });
    this.platformLocation.onPopState(() => {
      window.location.reload();
    })
  }
  ngOnInit() {}
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}