import { RouteInfo } from './sidebar.metadata';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    /*{ path: '/login', title: 'Login', icon: '', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },*/
    { path: '/dashboard', title: 'Dashboard', icon: 'fal fa-desktop', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/salespeople/list', title: 'Sales Reps', icon: 'fal fa-chart-network', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    /*{ path: '/salespeople/list', title: 'Salespeople2', icon: 'ft-message-square', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },*/
    { path: '/uploadfile', title: 'File Uploads', icon: 'fal fa-sack-dollar', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/commission/list', title: 'Commissions', icon: 'fal fa-usd-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/reports', title: 'Reports', icon: 'fal fa-receipt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/regions', title: 'Regions', icon: 'fal fa-location-arrow', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/territories', title: 'Territories', icon: 'fal fa-map-marker-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/manufacturers', title: 'Manufacturers', icon: 'fal fa-industry-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/hospitals', title: 'Hospitals', icon: 'fal fa-hospital-alt', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/surgeons', title: 'Surgeons', icon: 'fal fa-user-md', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/procedures', title: 'Procedures', icon: 'fal fa-procedures', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/user/list', title: 'Users', icon: 'fal fa-user-friends', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/paymatrix', title: 'PayMatrix', icon: 'fal fa-money-check', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
    { path: '/repdashboard', title: 'RepDashboard', icon: 'fal fa-desktop', class: '', badge: '', badgeClass:    '', isExternalLink: false, submenu: [] },

]; 
