import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CommonModule } from "@angular/common";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { ToastrModule } from "ngx-toastr";
import { AgmCoreModule } from "@agm/core";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { StoreModule } from "@ngrx/store";
import { MapUtilityScreenComponent } from './uploadfile/modals/maputility.screen.component';
//import { MapDataUtilityScreenComponent } from './uploadfile/modals/mapdatautility.screen.component';
import { UploadNewFileScreenComponent } from './uploadfile/modals/uploadnewfile.screen.component';
import { ManageRegionScreenComponent } from './regions/modals/manageregion.screen.component';
import { AssignRegionScreenComponent } from './territories/modals/assignregion.screen.component';
import { NoteScreenComponent } from './uploadfile/modals/note.screen.component';
import { EditRuleScreenComponent } from './salespeople/modals/editrule.screen.component';
import { EditBonusRuleScreenComponent } from './salespeople/modals/editbonusrule.screen.component';
import { ActivateRuleScreenComponent } from './salespeople/modals/activaterule.screen.component';
import { CommissionDetailScreenComponent } from './commission/modals/commissiondetail.screen.component';
import { EditAdjustmentScreenComponent } from './commission/modals/editadjustment.screen.component';
import { EditSAdjustmentScreenComponent } from './salespeople/modals/editadjustment.screen.component';
import { EditSalaryDrawScreenComponent } from './salespeople/modals/editsalarydraw.screen.component';
import { EditGuaranteeScreenComponent } from './salespeople/modals/editguarantee.screen.component';
import { AddSecondaryEmailScreenComponent } from './salespeople/modals/addsecondaryemail.screen.component';
import { CalculateCommissionHistoryComponent } from './commission/modals/calculatecommissionhistory.screen.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NotifierModule } from 'angular-notifier';
import { NgxSpinnerModule } from "ngx-spinner";
import { ConfigService } from './core/config';
import { HelperService } from './core/helper';
import { NgSelectModule } from '@ng-select/ng-select';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { EditProfileScreenComponent } from './user/modals/editprofile.screen.component';
import { CookieService } from 'ngx-cookie-service';
import { ChartsModule } from 'ng2-charts';
import { SocketIoConfig, SocketIoModule } from 'ngx-socket-io';
import { UserService } from "./user/user.service";

//import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MatDatepickerModule, MatNativeDateModule, MatInputModule, MatSlideToggleModule } from '@angular/material';

const config: SocketIoConfig = { url: '', options: {} };



//import { SelectDropDownModule } from 'ngx-select-dropdown'


import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';

import { AppComponent } from "./app.component";
import { ContentLayoutComponent } from "./layouts/content/content-layout.component";
import { FullLayoutComponent } from "./layouts/full/full-layout.component";

import { DragulaService } from "ng2-dragula";
import { AuthService } from "./shared/auth/auth.service";
import { AuthGuard } from "./shared/auth/auth-guard.service";

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelPropagation: false
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}


@NgModule({
  declarations: [AppComponent, FullLayoutComponent, ContentLayoutComponent,
    MapUtilityScreenComponent, UploadNewFileScreenComponent, NoteScreenComponent,
    EditRuleScreenComponent, EditBonusRuleScreenComponent, ActivateRuleScreenComponent, CommissionDetailScreenComponent, CalculateCommissionHistoryComponent, EditAdjustmentScreenComponent, EditSAdjustmentScreenComponent, EditSalaryDrawScreenComponent, EditProfileScreenComponent, AssignRegionScreenComponent, ManageRegionScreenComponent, AddSecondaryEmailScreenComponent, EditGuaranteeScreenComponent],
  entryComponents: [MapUtilityScreenComponent, UploadNewFileScreenComponent, NoteScreenComponent,
    EditRuleScreenComponent, EditBonusRuleScreenComponent, ActivateRuleScreenComponent, CommissionDetailScreenComponent, CalculateCommissionHistoryComponent, EditAdjustmentScreenComponent, EditSAdjustmentScreenComponent, EditSalaryDrawScreenComponent, EditProfileScreenComponent, AssignRegionScreenComponent, ManageRegionScreenComponent, AddSecondaryEmailScreenComponent, EditGuaranteeScreenComponent],
  imports: [
    ChartsModule,
    NgxSpinnerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatSlideToggleModule,
    NotifierModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}),
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgSelectModule,
    CommonModule,
    // OwlDateTimeModule,
    //OwlNativeDateTimeModule,


    // SelectDropDownModule,
    ToastrModule.forRoot(),
    NgbModule.forRoot(),
    FlashMessagesModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: "YOUR KEY"
    }),
    PerfectScrollbarModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    CookieService,
    ConfigService,
    HelperService,
    AuthService,
    AuthGuard,
    DragulaService,
    UserService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG }
  ],
  bootstrap: [AppComponent],
  exports: [MapUtilityScreenComponent, UploadNewFileScreenComponent, NoteScreenComponent, EditRuleScreenComponent, EditBonusRuleScreenComponent,
    ActivateRuleScreenComponent, CommissionDetailScreenComponent, EditAdjustmentScreenComponent,
    EditSalaryDrawScreenComponent, EditProfileScreenComponent, AssignRegionScreenComponent, ManageRegionScreenComponent, AddSecondaryEmailScreenComponent, EditGuaranteeScreenComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
