import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-addsecondaryemail-screen',
  templateUrl: './addsecondaryemail.screen.component.html'
})

export class AddSecondaryEmailScreenComponent {  
  formValidationList: any = {
    email: '',
  }
  secondaryEmails: any = {};
  email:string;  
  constructor(
    public activeModal: NgbActiveModal,
  ) {

  }

  closePopup() {
    this.activeModal.close({});
  }
  performEmailValidation() {
    let isValid = true;
    if (!this.email) {
      this.formValidationList.email = 'This field is required!';
      isValid = false;
    } else if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
      this.formValidationList.email = 'You have entered an invalid email address!';
      isValid = false;
    }else {
      this.formValidationList.email = '';
    }
    return isValid;
  }
  addSecondaryEmail() {
    const isValid = this.performEmailValidation();
    if (isValid) {
      this.secondaryEmails['secondaryEmailsList'] = this.email;
      this.activeModal.close(this.secondaryEmails);
    }
  }


}
