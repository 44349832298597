import { Component, ViewEncapsulation, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-note-screen',
  templateUrl: './note.screen.component.html'

})

export class NoteScreenComponent {
  @Input() description;
  constructor(
    public activeModal: NgbActiveModal) {
  }
  closePopup() {
    this.activeModal.close('Close click');
  }
}
