import { Component, ViewEncapsulation, Input, OnInit, ViewChild, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommissionService } from '../commission.service';
import { SalespeopleHelper } from 'app/salespeople/salespeople.helper';

@Component({
  selector: 'app-editadjustment-screen',
  templateUrl: './editadjustment.screen.component.html',
  styleUrls: ['./editadjustment.screen.component.scss'],
  providers: [
    CommissionService,
    SalespeopleHelper
  ]
})

export class EditAdjustmentScreenComponent implements OnInit {
  adjustmentManufacturerCustomOption: string = null;
  adjustmentsValidationList: any;
  manufacturers: Array<any> = [];
  @Input() adjustmentId;
  @Input() adjustmentType;
  @Input() adjustmentAmount;
  @Input() adjustmentDescription;
  @Input() selectedManufacturerAdjustment;
  @Input() primaryTerritory;
  @Input() selectedMonthYear;
  @Input() salesPersonId;

  constructor(
    private spinner: NgxSpinnerService,
    public activeModal: NgbActiveModal,
    private _commissionService: CommissionService,
    private _salespeopleHelper: SalespeopleHelper
  ) { }

  ngOnInit() {
    this.resetAdjustmentFormValidationMessages();
    this._commissionService.getManufacturerList().subscribe(
      (manufacturerListData) => {
        this.manufacturers = manufacturerListData.data.manufacturerData;
        this.manufacturers.unshift({ id: 0, manufacturerName: "Add New" });
      },
      error => console.log(error)
    )
  }
  updateAdjustment() {
    const isAdjustmentValid = this.performAdjustmentValidation();
    if (isAdjustmentValid) {
      const params = {
        'selectedMonthYear': this.selectedMonthYear,
        'adjustmentType': this.adjustmentType,
        'adjustmentAmount': this.adjustmentAmount,
        'adjustmentDescription': this.adjustmentDescription,
        'adjustmentId': this.adjustmentId,
        'manufacturer': this.selectedManufacturerAdjustment,
        'territory': this.primaryTerritory,
        'salesPersonId': this.salesPersonId,
        'adjustmentManufacturerCustomOption': (this.adjustmentManufacturerCustomOption) ? this.adjustmentManufacturerCustomOption.trim() : ''

      };
      this._commissionService.updateAjustment(params).subscribe(
        () => {
          this.activeModal.close({ "ajustmentUpdated": true });
        },
        (error) => { }
      )
    }
  }

  performAdjustmentValidation() {
    let isAdjustmentValid = true;
    this.resetAdjustmentFormValidationMessages();
    if (this.adjustmentAmount === "") {
      isAdjustmentValid = false;
      this.adjustmentsValidationList.adjustmentAmount = "This field is required!";
    } else if (!String(this.adjustmentAmount).match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      isAdjustmentValid = false;
      this.adjustmentsValidationList.adjustmentAmount = "Only number allowed";
    }

    if (!this.adjustmentDescription) {
      isAdjustmentValid = false;
      this.adjustmentsValidationList.adjustmentDescription = "This field is required!";
    }
    // Manufacturer validation
    const {
      manufacturerIsValid,
      manufacturerValidationList,
      manufacturerCustomOption
    } = this._salespeopleHelper.validateManufacturerWithoutRequired(
      {
        selectedManufacturer: this.selectedManufacturerAdjustment,
        validationList: this.adjustmentsValidationList,
        manufacturerCustomOption: this.adjustmentManufacturerCustomOption,
        isValid: isAdjustmentValid,
        manufacturers: this.manufacturers
      }
    );
    isAdjustmentValid = manufacturerIsValid;
    this.adjustmentsValidationList = manufacturerValidationList;
    this.adjustmentManufacturerCustomOption = manufacturerCustomOption; 
    // Manufacturer validation ends
    return isAdjustmentValid;
  }
  resetAdjustmentFormValidationMessages() {
    this.adjustmentsValidationList = { adjustmentAmount: "", adjustmentDescription: "", manufacturers: "" }
  }
  closePopup() {
    this.activeModal.close({});
  }
}
