import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadFileService } from '../uploadfile.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
   selector: 'app-maputility-screen',
   templateUrl: './maputility.screen.component.html',
   styleUrls: ['./maputility.screen.component.scss'],
   encapsulation: ViewEncapsulation.None,
   providers: [
      UploadFileService
   ]
})

export class MapUtilityScreenComponent implements OnInit {

   entries: Array<any>;
   mappedFields: Object;
   hasError: Boolean;
   manufactuerName: string;
   isHospitalSelected: boolean;
   isSurgeonSelected: boolean;
   isProcedureSelected: boolean;
   isTerritorySelected: boolean;
   isAmountSelected: boolean;
   isGrossCommissionSelected: boolean;
   isInvoiceDateSelected: boolean;
   selectedMonthYear: string;
   @Input() fileId;
   constructor(
      private _uploadFileService: UploadFileService,
      public activeModal: NgbActiveModal,
      private spinner: NgxSpinnerService
   ) {
      this.entries = [];
      this.mappedFields = {};
      this.selectedMonthYear = null;
   }
   ngOnInit() {

      this.spinner.show();
      this.selectedMonthYear = localStorage.getItem('selectedMonthYearLocalStorageUpload');
      this.hasError = false;
      this.resetFieldSelection();
      this._uploadFileService.getEntries(this.fileId).subscribe(
         (data) => {
            const columnList = data.columnList;
            this.manufactuerName = data.manufactuerName;
            const previousMappedData = data.previousMappedData;
            const previousMappedDataCount = Object.keys(previousMappedData).length;
            const columnNames = Object.keys(columnList);
            for (let index = 0; index < columnNames.length; index++) {
               if (previousMappedDataCount > 0 && previousMappedData[columnNames[index]]) {
                  this.mappedFields[columnNames[index]] = previousMappedData[columnNames[index]];
                  if (previousMappedData[columnNames[index]] == 'hospital') {
                     this.isHospitalSelected = true;
                  }
                  if (previousMappedData[columnNames[index]] == 'surgeon') {
                     this.isSurgeonSelected = true;
                  }
                  if (previousMappedData[columnNames[index]] == 'procedure') {
                     this.isProcedureSelected = true;
                  }
                  if (previousMappedData[columnNames[index]] == 'territory') {
                     this.isTerritorySelected = true;
                  }
                  if (previousMappedData[columnNames[index]] == 'amount') {
                     this.isAmountSelected = true;
                  }
                  if (previousMappedData[columnNames[index]] == 'grossCommission') {
                     this.isGrossCommissionSelected = true;
                  }
                  if (previousMappedData[columnNames[index]] == 'invoicedate') {
                     this.isInvoiceDateSelected = true;
                  }
                  if (previousMappedData[columnNames[index]] == 'N/A') {
                     this.mappedFields[columnNames[index]] = null;
                  }
               } else {
                  this.mappedFields[columnNames[index]] = "";
               }
               this.entries.push({ 'columnName': columnNames[index], 'sampleEntries': columnList[columnNames[index]] });
            }
            this.processFieldsWithError();
            this.spinner.hide();
         },
         error => console.log(error)
      );
   }
   resetFieldSelection() {
      this.isHospitalSelected = false;
      this.isSurgeonSelected = false;
      this.isProcedureSelected = false;
      this.isTerritorySelected = false;
      this.isAmountSelected = false;
      this.isGrossCommissionSelected = false;
      this.isInvoiceDateSelected = false;
   }

   changeeventhandler() {

      this.processFieldsWithError();
   }
   submitEventHandler(el: HTMLElement) {
      this.processFieldsWithError();
      if (!this.hasError) {
         this.spinner.show();
         const params = {
            'mappedFields': this.mappedFields,
            'fId': this.fileId,
            'selectedMonthYear': this.selectedMonthYear
         };
         this._uploadFileService.storeMappedFields(params).subscribe(
            (data) => {
               this.spinner.hide();
               this.closePopup();
            },
            (error) => {
               this.spinner.hide();
               this.closePopup();
            }
         )
      } else {
         console.log('error');
         el.scrollIntoView();
      }
   }
   processFieldsWithError() {
      this.resetFieldSelection();

      for (let key in this.mappedFields) {
         if (this.mappedFields[key] == 'hospital') {
            this.isHospitalSelected = true;
         }
         if (this.mappedFields[key] == 'surgeon') {
            this.isSurgeonSelected = true;
         }
         if (this.mappedFields[key] == 'procedure') {
            this.isProcedureSelected = true;
         }
         if (this.mappedFields[key] == 'territory') {
            this.isTerritorySelected = true;
         }
         if (this.mappedFields[key] == 'amount') {
            this.isAmountSelected = true;
         }
         if (this.mappedFields[key] == 'grossCommission') {
            this.isGrossCommissionSelected = true;
         }
         if (this.mappedFields[key] == 'invoicedate') {
            this.isInvoiceDateSelected = true;
         }
      }
      if (this.isHospitalSelected && this.isSurgeonSelected
         && this.isProcedureSelected && this.isTerritorySelected
         && this.isAmountSelected && this.isGrossCommissionSelected && this.isInvoiceDateSelected) {
         this.hasError = false;

      } else {
         this.hasError = true;
      }
   }
   closePopup() {
      this.activeModal.close('Close click');
   }
}
