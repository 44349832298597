import { Component, OnInit, Input, ViewChild, OnDestroy, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

import { ROUTES } from './sidebar-routes.config';
import { RouteInfo } from './sidebar.metadata';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from '../animations/custom-animations';
import { FlashMessagesService } from 'angular2-flash-messages';
import { ConfigService } from '../services/config.service';
import { LayoutService } from '../services/layout.service';
import { Subscription } from 'rxjs';
import { Location, PlatformLocation } from '@angular/common';
import { UserService } from '../../user/user.service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon', { static: false }) toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo-2.svg';
  public config: any = {};
  layoutSub: Subscription;
  screenAccess: any = {};
  dashAccess =  false;
  salesRepAccess = false;
  fileUploadAccess = false;
  reportsAccess = false;
  commissionAccess = false;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,
    private _flashMessagesService: FlashMessagesService,
    private layoutService: LayoutService,
    private location: Location,
    private platformLocation: PlatformLocation,
    private _userService: UserService,
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }

    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
      options => {
        if (options) {
          if (options.bgColor) {
            if (options.bgColor === 'white') {
              this.logoUrl = 'assets/img/logo-dark.png';
            } else {
              this.logoUrl = 'assets/img/logo-2.svg';
            }
          }

          if (options.compactMenu === true) {
            this.expanded = false;
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = true;
          } else if (options.compactMenu === false) {
            this.expanded = true;
            this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
            this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
            this.nav_collapsed_open = false;
          }

        }
      });

  }


  ngOnInit() {
    this.config = this.configService.templateConf;
    this.menuItems = ROUTES;
    console.log(this.menuItems);
    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    } else {
      this.logoUrl = 'assets/img/logo-2.svg';
    }
    this._userService.getMe().subscribe(
      (meData) => {
        if ( meData.data.Role.name === 'Staff' ) {
          this.screenAccess = meData.data.screenAccess;
          const screenAccessList = this.screenAccess.split(',');

          this.dashAccess = screenAccessList.filter(
            record => (record.toLowerCase() === 'dashboard')
          )
          this.salesRepAccess = screenAccessList.filter(
            record => (record.toLowerCase() === 'salespeople-list')
          )
          this.fileUploadAccess = screenAccessList.filter(
            record => (record.toLowerCase() === 'uploadfile')
          )
          this.commissionAccess = screenAccessList.filter(
            record => (record.toLowerCase() === 'commission-list')
          )
          this.reportsAccess = screenAccessList.filter(
            record => (record.toLowerCase() === 'reports')
          )
          const screenAccessArray = [];
          ROUTES.forEach(async (route) => {
            const routes = route.path.replace('/', '');
            const screensAccessList = routes.replace('/', '-');
            if ( this.dashAccess[0] === screensAccessList ) {
              screenAccessArray.push(route);
            }
            if ( this.salesRepAccess[0] === screensAccessList ) {
              screenAccessArray.push(route);
            }
            if ( this.fileUploadAccess[0] === screensAccessList ) {
              screenAccessArray.push(route);
            }
            if ( this.commissionAccess[0] === screensAccessList ) {
              screenAccessArray.push(route);
            }
            if ( this.reportsAccess[0] === screensAccessList ) {
              screenAccessArray.push(route);
            }
            if (screensAccessList === 'regions' || screensAccessList === 'territories' ||
                    screensAccessList === 'hospitals' || screensAccessList === 'procedures' || screensAccessList === 'surgeons') {
                screenAccessArray.push(route);
            }
          });
          this.menuItems = screenAccessArray;
        } else {
          this.menuItems = ROUTES;
        }
      });

  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        } else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;

          // this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          // this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');


          this.nav_collapsed_open = false;
        }
      }
    }, 0);


  }

  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
  }

  toggleSlideInOut() {
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf('forms/ngx') !== -1)
      this.router.navigate(['forms/ngx/wizard'], { skipLocationChange: false });
  }
}
