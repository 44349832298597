import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../core/config';

@Injectable()
export class ReportsService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }
  getSummaryCommissionBySalesperson(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getSummaryCommissionBySalesperson`, params);
  }
  getSummaryCommissionByManufacturer(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getSummaryCommissionByManufacturer`, params);
  }
  getDetailedCommissionBySalesperson(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getDetailedCommissionBySalesperson`, params);
  }
  getSalesDataLineItems(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getSalesDataLineItems`, params);
  }

  getDrawBySalesperson(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getDrawBySalesperson`, params);
  }
  getExpiredRules(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getExpiredRules`, params);
  }
  getExpiredAdjustments(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getExpiredAdjustments`, params);
  }
  getSalespersonList(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/reports/getSalespersonList`);
  }
  getSelectedSalesPersonCommissionDetailInMonthRange(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getSelectedSalesPersonCommissionDetailInMonthRange`, params);
  }
  getSelectedSalesPersonIncomeByMonthsInMonthRange(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/reports/getSelectedSalesPersonIncomeByMonthsInMonthRange`, params);
  }


}