import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../core/config';

@Injectable()
export class UploadFileService {
   constructor(
      private http: HttpClient,
      private configService: ConfigService
   ) {
   }
   getMasterData(filter): Observable<any> {
      return this.http.post(`${this.configService.apiUrl}/uploadprocess/getMasterDropdownsData`, { filter: filter });
   }
   // Makes call to uploadAPI endpoint
   submitUploadForm(params, files) {
      return new Promise((resolve, reject) => {
         const formData: any = new FormData();
         const xhr = new XMLHttpRequest();
         formData.append('note', params.note);
         formData.append('manufacturerId', params.manufacturerId);
         formData.append('selectedMonthYear', params.selectedMonthYear);
         formData.append('addNewManufacturer', params.addNewManufacturer);
         for (let loopIndex = 0; loopIndex < files.length; loopIndex++) {
            formData.append('filetoupload', files[loopIndex], files[loopIndex].name);
         }
         xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
               if (xhr.status === 200) {
                  resolve(JSON.parse(xhr.response));
               } else {
                  reject(xhr.response);
               }
            }
         }
         xhr.open('POST', `${this.configService.apiUrl}/uploadprocess/uploadfile`, true);
         xhr.send(formData);
      });
   }
   getfilelist(selectedMonthYear): Observable<any> {
      return this.http.post(`${this.configService.apiUrl}/uploadprocess/getfilelist`, { selectedMonthYear });
   }
   getEntries(fId): Observable<any> {
      return this.http.post(`${this.configService.apiUrl}/uploadprocess/getFileHeaders`, { fId: fId });
   }
   processMapValues(fId): Observable<any> {
      return this.http.post(`${this.configService.apiUrl}/uploadprocess/processMapValues`, { fId: fId });
   }
   storeMappedFields(params): Observable<any> {
      return this.http.post(`${this.configService.apiUrl}/uploadprocess/storeMappedFields`, params);
   }
   storeMappedValues(params): Observable<any> {
      return this.http.post(`${this.configService.apiUrl}/uploadprocess/storeMappedValues`, params);
   }
   deleteUploadedFileRecord(params): Observable<any> {
      return this.http.post(`${this.configService.apiUrl}/uploadprocess/deleteUploadedFileRecord`, params);
   }
}
