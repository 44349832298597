import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../core/config';

@Injectable()
export class SalesPeopleService {
  public secondaryEmails = '';
  constructor(
    private http: HttpClient,
    private configService: ConfigService

  ) {
  }
  fetchMasterData(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/salesperson/getMasterDropdownsData`);
  }

  getSalespersonList(selectedPrimaryTerritory): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/salesperson/getSalespersonList/${selectedPrimaryTerritory}`);
  }
  getSelectedSalespersonDetail(salesPersonId): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/salesperson/getSelectedSalespersonDetail/${salesPersonId}`);
  }

  storeSalesPersonData(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/salesperson/storeSalesPersonData`, params);
  }
  updateSalesPersonData(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/salesperson/updateSalesPersonData`, params);
  }
  removeSalesPerson(id): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/salesperson/removeSalesPerson/${id}`);
  }
  getPrimaryTeritories(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/commission/getPrimaryTeritories`);
  }  
  storeLedgerBalanceData(params):  Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/salesperson/storeLedgerBalanceData`, params);
  }
  updateLedgerBalanceData(params):  Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/salesperson/updateLedgerBalanceData`, params);
  }
}
