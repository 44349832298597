import { Component, ViewEncapsulation, Input, OnInit, ViewChild, Output } from '@angular/core';

import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HelperService } from '../../core/helper';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Component({
    selector: 'app-activaterule-screen',
    templateUrl: './activaterule.screen.component.html',
    styleUrls: ['./activaterule.screen.component.scss'],
    providers: [
        HelperService,
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ]
})

export class ActivateRuleScreenComponent implements OnInit {
    ruleStartMonthYearEle = new FormControl();
    ruleEndMonthYearEle = new FormControl();
    ruleEndMonthMinLimit: any;
    ruleNoneChecked: boolean;
    activateRuleResponse: any;
    @Input() selectedTerritory;
    @Input() territoryCustomOption;
    @Input() selectedRegion;
    @Input() regionName;
    @Input() selectedManufacturer;
    @Input() manufacturerCustomOption;
    @Input() selectedHospital;
    @Input() hospitalCustomOption;
    @Input() selectedSurgeon;
    @Input() surgeonCustomOption;
    @Input() selectedProcedure;
    @Input() procedureCustomOption;
    @Input() ruleStartMonthList;
    @Input() ruleEndMonthList;
    @Input() ruleStartMonthYear;
    @Input() ruleEndMonthYear;
    @Input() ruleMonthList
    @Input() commission;

    constructor(
        private spinner: NgxSpinnerService,
        private _helper: HelperService,
        public activeModal: NgbActiveModal) {
        this.activateRuleResponse = {};
    }
    ngOnInit() {
        // this.populateRuleEndMonthDropdown();
        this.ruleStartMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.ruleStartMonthYear));
        this.ruleEndMonthMinLimit = this._helper.getFormattedMonthYear(this.ruleStartMonthYear);
        if (this.ruleEndMonthYear !== 'None') {
            this.ruleEndMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.ruleEndMonthYear));
            this.ruleNoneChecked = false;
        } else {
            this.ruleNoneChecked = true;
        }
    }


    applyActivation() {
        this.activateRuleResponse['startMonth'] = this.ruleStartMonthYear;
        this.activateRuleResponse['endMonth'] = this.ruleEndMonthYear;
        this.activeModal.close(this.activateRuleResponse);

    }
    closePopup() {
        this.activeModal.close({});
    }
    chosenRuleStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        this.ruleStartMonthYear = moment(normalizedMonth).format('MMMM-YYYY');
        this.ruleStartMonthYearEle.setValue(moment(normalizedMonth));
        // this.ruleEndMonthYear = moment(normalizedMonth).format("MMMM-YYYY");
        // this.ruleEndMonthYearEle.setValue(moment(normalizedMonth));
        // this.ruleEndMonthMinLimit = moment(normalizedMonth);
        datepicker.close();
    }
    chosenRuleEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
        this.ruleEndMonthYear = moment(normalizedMonth).format('MMMM-YYYY');
        this.ruleEndMonthYearEle.setValue(moment(normalizedMonth));
        datepicker.close();
    }
    onRuleNoneChange(ob: MatSlideToggleChange) {
        if (ob.checked) {
            this.ruleEndMonthYear = 'None';
        } else {
            this.ruleEndMonthYear = this.ruleStartMonthYear;
            this.ruleEndMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.ruleStartMonthYear));
            this.ruleEndMonthMinLimit = this._helper.getFormattedMonthYear(this.ruleStartMonthYear);
        }
        this.ruleNoneChecked = ob.checked;
    }
}
