import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../core/config';
const AmazonCognitoIdentity  = require('amazon-cognito-identity-js');
import {environment} from "../../environments/environment";
import swal from 'sweetalert2';
@Injectable()
export class UserService {
  // getRoleList() {
  //   throw new Error("Method not implemented.");
  // }
  headerOptions: any;
  guesUserLinks: any;
  cognitoUser: any = null;
  poolData:any = {};
  fromEmail: string = '';
  fromName: string = '';
  userPool: any;
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {   
    this.guesUserLinks = ['', '/login/forgotpassword', '/login', '/login/resetpassword'];
    this.headerOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      withCredentials: true
    };
  }
  setCogUpVal() {
    return this.http.get(`${this.configService.apiUrl}/user/cuVal`);
  }
  signOutWithCognito() {
    const cognitoUser = this.userPool.getCurrentUser();
    cognitoUser.signOut();
  }
   checkUserSession() {
    //console.log('checkUserSession');
    return new Promise((resolve, reject) => {
      this.setCogUpVal().subscribe((cogUpValRes: any) => {
        const cogUpValData = JSON.parse(cogUpValRes.data);
        this.poolData.UserPoolId = cogUpValData.u;
        this.poolData.ClientId = cogUpValData.c;
        this.fromEmail = cogUpValData.fromEmail;
        this.fromName = cogUpValData.fromName;
        this.userPool = new AmazonCognitoIdentity.CognitoUserPool(this.poolData);
        //console.log(this.userPool);
        //console.log('inside setCogUpVal');
        const cognitoUser = this.userPool.getCurrentUser();
        if(cognitoUser) {
          cognitoUser.getSession(function(error, session) {
            //console.log('session');
            //console.log(session);
            if(error) {
              reject(new Error(error.message));
            }
            resolve(session.isValid());
            //console.log(`session validity: ${session.isValid()}`);
            //console.log(`Refresh token`);
            //console.log(session.getRefreshToken());
          })
        } else {
          resolve(false);          
          console.log('else');
        }        
      },
      (error) => {
        console.log(error);
      });
    });
  }  
  confirmUser(paramObj) {
    const userData = {
      Username: paramObj.userName,
      Pool: this.userPool
    };
    this.cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return new Promise((resolve, reject) => {
      this.cognitoUser.confirmRegistration(paramObj.confirmationCode, true, function(error, result) {
        if(error) {
          reject(new Error(error.message))
          return;
        }
        console.log(result);
        resolve({status: 'success'});
      })
    });    
  }
  signUpWithCognito(paramObj): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/signUpWithCognito`, paramObj);
  }
  signInWithCognito(paramObj) {
    paramObj.error = '';
    const authenticationDetailsParams = {
      Username: paramObj.email,
      Password: paramObj.password,
    };
    const userData = {
      Username: paramObj.email,
      Pool: this.userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    paramObj.cognitoUser = cognitoUser;
    console.log(paramObj.rememberMeActive);
    if(!paramObj.rememberMeActive && paramObj.isTwoFaEnabled) {
      // 2FA
      authenticationDetailsParams['challengeName'] = 'CUSTOM_CHALLENGE';
      cognitoUser.setAuthenticationFlowType('CUSTOM_AUTH');
    }
    const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(
      authenticationDetailsParams
    );    
    return new Promise((resolve,reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function(result) {
          paramObj.spinnerObj.hide();
          resolve({ setFirstTimePasswordFlow: false, token: result });
        },
        onFailure: function(error){
          paramObj.spinnerObj.hide();
          reject(new Error(error.message));
        },
        customChallenge : function()  {
          paramObj.spinnerObj.hide();
          paramObj.flashMessagesServiceObj.show('Two-Factor Authentication Verification Code email sent successfully.', { cssClass: 'alert-success', timeout: 4000 });
          swal.fire({
            title: 'Enter Verification Code',
            input:  'text',
            showCancelButton: true,
            confirmButtonText: 'Verify Code',
            showLoaderOnConfirm: false,
            preConfirm: (verificationCode) => {
              if(!verificationCode || verificationCode.trim() === "") {
                swal.showValidationMessage("Please enter Verification Code!");
              } else {
                console.log('else2');
                return new Promise((resolve1, reject1) => {
                  paramObj.cognitoUser.sendCustomChallengeAnswer(verificationCode, {
                    onSuccess: function(result) {
                      paramObj.spinnerObj.hide();
                      resolve1({ setFirstTimePasswordFlow: false, token: result });
                    },
                    onFailure: function(error){
                      reject1(error.message);
                    },
                    customChallenge: function() {
                      reject1("Incorrect Verification Code");
                    }    
                  })
                }).then((tokenResult: any) => {
                  paramObj.token = tokenResult.token;
                  paramObj.setFirstTimePasswordFlow = tokenResult.setFirstTimePasswordFlow;
                })
                .catch((error) => {
                  if(error === 'Incorrect username or password.') {
                    paramObj.error = 'You have exhausted 3 attempts to enter Verification Code, please resend the Verification Code and complete the authentication process!';  
                  } else {
                    swal.showValidationMessage(error);
                  }
                });
              }
            }
          }).then((result: any) => {
            if(result.dismiss === "cancel") {
              reject("The operation could not be completed, please resend the Verification Code and complete the authentication process!");
            } else if(paramObj.error !== '') {
              reject(paramObj.error);
            } else {
              resolve({ setFirstTimePasswordFlow: paramObj.setFirstTimePasswordFlow, token: paramObj.token });
            }
          })
        },
        newPasswordRequired: function(userAttributes, _requiredAttributes) {
          delete userAttributes.phone_number;
          delete userAttributes.email;
          delete userAttributes.email_verified;
          delete userAttributes.phone_number_verified;
          if(paramObj.newPassword !== '') {
            cognitoUser.completeNewPasswordChallenge(paramObj.newPassword, userAttributes, {
              onSuccess: function(result) {
                resolve({ setFirstTimePasswordFlow: false, token: result });
              },
              onFailure: function(error){
                reject(error.message);
              },
            });
          } else {
            resolve({ setFirstTimePasswordFlow: true});
          }
        }  
      });
    })
  }
  getUserList(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/getUserList`);
  }
  getRoleList(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/getRoleList`);
  }
  storeUserData(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/storeUserData`, params);
  }
  updateUserData(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/updateUserData`, params);
  }
  updateUserEmail(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/updateUserEmail`, params);
  }
  updateSmsOptIn(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/updateSmsOptIn`, params);
  }
  updateUserPhone(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/updateUserPhone`, params);
  }
  updateUserConfirmationStatus(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/updateUserConfirmationStatus`, params);
  }
  removeUser(id): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/removeUser/${id}`);
  }
  authenticateUser(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/authenticateUser`, params, this.headerOptions);
  }
  isLoggedInUser(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/isLoggedInUser`, this.headerOptions);
  }
  logout(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/logout`, this.headerOptions);
  }
  getMe(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/getMe/${localStorage.getItem('loginToken')}`);
  }
  getTwoFAConfigDetail(email): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/getTwoFAConfigDetail/${email}`);
  }
  getConfirmationStatus(cogUserName): Observable<any> {
    console.log(`getConfirmationStatus${this.configService.apiUrl}/user/getConfirmationStatus/${cogUserName}`);
    return this.http.get(`${this.configService.apiUrl}/user/getConfirmationStatus/${cogUserName}`);
  }
  forgotPasswordWithCognito(email) {
    const userData = {
      Username: email,
      Pool: this.userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: function() {
          resolve(true);
        },
        onFailure: function(error) {
          reject(new Error(error.message))
        }
      })
    });
  }
  forgotPassword(email) {    
  return this.http.get(`${this.configService.apiUrl}/user/forgotPassword/${email}`);
  }
  verifyResetPasswordAccess(cogUserName): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/user/verifyResetPasswordAccess/${cogUserName}`);
  }
  changePasswordInCognito(params) {
    const currentUser  = this.userPool.getCurrentUser();
    return new Promise((resolve, reject) => {
      currentUser.getSession(function(error, session) {
        if(error) {
          reject(error.message);
        }
        currentUser.changePassword(params.oldPassword, params.password, function(error, result) {
          if(error) {
            if(error.message === 'Incorrect username or password.') {
              error.message = 'Old Password is Incorrect.';
            }
            reject(error.message);
          }
          resolve(result);
        })
      });
    });
  }
  updatePhoneAttributeInCognito(params) {
    params.thisObj = this;
    const attributeList = [];
    const phoneData = {
      Name: 'phone_number',
      Value: `+1${params.newPhone}`
    }
    const attributePhone = new AmazonCognitoIdentity.CognitoUserAttribute(phoneData);
    attributeList.push(attributePhone);
    const currentUser  = this.userPool.getCurrentUser();    
    return new Promise((resolve, reject) => {
      currentUser.getSession(function(error, session) {
        if(error) {
          reject(error.message);
        }        
        currentUser.updateAttributes(attributeList, function(error, _result) {
          if(error) {
            reject(error.message);
          }
          params.spinnerObj.hide();
          params.flashMessagesServiceObj.show('Verification code sms sent successfully.', { cssClass: 'alert-success', timeout: 4000 })
          swal.fire({
            title: 'Enter Verification Code',
            input: 'text',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Verify Code',
            showLoaderOnConfirm: true,
            preConfirm: (verificationCode) => {
              if(!verificationCode || verificationCode.trim() === "") {
                swal.showValidationMessage("Please enter verification code!");
              } else {
                 return new Promise((resolve1, reject1) => {
                  currentUser.verifyAttribute('phone_number', verificationCode,{
                    onSuccess: function() {
                      resolve1(true);
                    },
                    onFailure: function(error){
                      reject1(error.message);
                    }    
                  });
                })
                .then(() => {})
                .catch((error) => {
                  swal.showValidationMessage(error);
                });
              }
            }
          })
          .then((result) => {
            if(result.dismiss) {
              reject('Operation could not complete, please complete verification to update phone!');
              return;
            }
            resolve(true);
          })
        });
      })
    });
  }
  updateSmsOptInAttributeInCognito(params) {
    params.thisObj = this;
    console.log('updateSmsOptInAttributeInCognito');
    const attributeList = [];
    const smsOptInData = {
      Name: 'custom:smsOptIn',
      Value: (params.smsOptIn) ? "true": "false"
    };
    const attributeSmsOptIn = new AmazonCognitoIdentity.CognitoUserAttribute(smsOptInData);
    attributeList.push(attributeSmsOptIn);
    const currentUser  = this.userPool.getCurrentUser();    
    return new Promise((resolve, reject) => {
      currentUser.getSession(function(error, session) {
        if(error) {
          reject(error.message);
        }        
        currentUser.updateAttributes(attributeList, function(error, _result) {
          if(error) {
            reject(error.message);
          }
          resolve(true);          
        });
      })
      
    });
  }
  updateEmailAttributeInCognito(params) {
    params.thisObj = this;
    console.log('updateAttributeInCognito');
    const attributeList = [];
    const emailData = {
      Name: 'email',
      Value: params.newEmail
    };
    const attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(emailData);
    attributeList.push(attributeEmail);
    const currentUser  = this.userPool.getCurrentUser();    
    return new Promise((resolve, reject) => {
      currentUser.getSession(function(error, session) {
        if(error) {
          reject(error.message);
        }        
        currentUser.updateAttributes(attributeList, function(error, _result) {
          if(error) {
            reject(error.message);
          }
          params.spinnerObj.hide();
          params.flashMessagesServiceObj.show('Verification code email sent successfully.', { cssClass: 'alert-success', timeout: 4000 })
          swal.fire({
            title: 'Enter Verification Code',
            input: 'text',
            showCancelButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Verify Code',
            showLoaderOnConfirm: true,
            preConfirm: (verificationCode) => {
              if(!verificationCode || verificationCode.trim() === "") {
                swal.showValidationMessage("Please enter verification code!");
              } else {
                 return new Promise((resolve1, reject1) => {
                  currentUser.verifyAttribute('email', verificationCode,{
                    onSuccess: function() {
                      resolve1(true);
                    },
                    onFailure: function(error){
                      reject1(error.message);
                    }    
                  });
                })
                .then(() => {})
                .catch((error) => {
                  swal.showValidationMessage(error);
                });
              }
            }
          })
          .then((result) => {
            if(result.dismiss) {
              reject('Operation could not complete, please complete verification to update email!');
              return;
            }
            resolve(true);
          })
        });
      })
      
    });
  }
  submitResetPasswordWithCognito(params) {
    const userData = {
      Username: params.email,
      Pool: this.userPool,
    };
    const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    console.log(params);
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(params.code, params.newPassword, {
        onSuccess() {
          resolve(true);
        },
        onFailure(error) {
          reject(new Error(error.message))
        }
      })
    });
  }
  submitResetPassword(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/user/submitResetPassword`, params);
  }
}
