import { Component, ViewEncapsulation, Input, OnInit, ViewChild, Output } from '@angular/core';
import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators, NgForm, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _moment from 'moment';
import { default as _rollupMoment, Moment } from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';
import { HelperService } from '../../core/helper';
import { SalespeopleHelper } from '../salespeople.helper';
const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-editguarantee-screen',
  templateUrl: './editguarantee.screen.component.html',
  styleUrls: ['./editguarantee.screen.component.scss'],
  providers: [
    HelperService,
    SalespeopleHelper,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})

export class EditGuaranteeScreenComponent implements OnInit {
  guaranteeValidationList: any;
  editGuaranteeResponse: any;
  guaranteeStartMonthList: any;
  guaranteeEndMonthList: any;
  guaranteeStartMonthYearEle = new FormControl();
  guaranteeEndMonthYearEle = new FormControl();
  guaranteeEndMonthMinLimit: any;
  guaranteeNoneChecked: boolean;
  guaranteeStartMonthYearFilter: any;
  guaranteeEndMonthMaxLimit: any;
  guaranteeNoneVisible: boolean;
  @Input() guaranteeStartMonthYear;
  @Input() guaranteeEndMonthYear;
  @Input() guaranteeAmount;
  @Input() guaranteeNote;
  @Input() isLastEntry;
  @Input() selectedManufacturerGuarantee;
  @Input() guaranteeManufacturerCustomOption;
  @Input() manufacturers;
  @Input() disabledGuaranteeMonthList;
  @Input() disabledSalaryDrawMonthList;
  @Input() guaranteeList;
  @Input() salaryDrawList;
  constructor(
    private spinner: NgxSpinnerService,
    private _helper: HelperService,
    private _salespeopleHelper: SalespeopleHelper,
    public activeModal: NgbActiveModal) {
  }
  ngOnInit() {
    this.guaranteeValidationList = { manufacturers: "", guaranteeAmount: "" };
    this.editGuaranteeResponse = {};
    this.guaranteeStartMonthList = [];
    this.guaranteeStartMonthYearEle.setValue(this._helper.getFormattedMonthYear(this.guaranteeStartMonthYear));
    const drawLastRecordEndMonth = (this.salaryDrawList.length > 0) ? this.salaryDrawList[this.salaryDrawList.length -1].endMonth : '';
    const guaranteeLastRecordEndMonth = (this.guaranteeList.length > 0) ? this.guaranteeList[this.guaranteeList.length -1].endMonth : '';
    const guaranteeLastRecordStartMonthDate = (this.guaranteeList.length > 0) ? this.guaranteeList[this.guaranteeList.length -1].startDate : '';
    const drawLastRecordStartMonthDate = (this.salaryDrawList.length > 0) ? this.salaryDrawList[this.salaryDrawList.length - 1].startDate : '';
    if (this.guaranteeEndMonthYear !== 'None' && drawLastRecordEndMonth !== 'None') {
      this.guaranteeNoneChecked = false;
      this.guaranteeNoneVisible = false;
    } else {
      this.guaranteeNoneChecked = true;
      this.guaranteeNoneVisible = true;
    }        
    this.updateGuaranteeEndMonthPicker(this.guaranteeStartMonthYear, 'onload');
    this.guaranteeStartMonthYearFilter = (d: Date) => {
      const monthYear = moment(d).format("MMMM-YYYY");
      if (this.disabledGuaranteeMonthList.includes(monthYear) || this.disabledSalaryDrawMonthList.includes(monthYear)) {
        let guaranteeEndMonthYear;
        if (this.guaranteeEndMonthYear == 'None') {
            guaranteeEndMonthYear = this._helper.getFormattedMonthYear("December-9999");
        } else {
            guaranteeEndMonthYear = this.guaranteeEndMonthYear;
        }
        if (moment(d).isBetween(moment(this.guaranteeStartMonthYear), moment(guaranteeEndMonthYear), null, '[]')) {
          return true;
        } else {
          return false;
        }
      } else {
        if(
          (drawLastRecordEndMonth === 'None' &&  moment(d).isAfter(moment(drawLastRecordStartMonthDate)))
          || (guaranteeLastRecordEndMonth === 'None' && this.guaranteeEndMonthYear !== 'None' && moment(d).isAfter(moment(guaranteeLastRecordStartMonthDate)))
          ) {
          return false;
        } else {
          return true;
        }
      }
    }
  }
  updateGuaranteeEndMonthPicker(guaranteeStartMonth, source) {
    const guaranteeStartDate = moment(guaranteeStartMonth);
    const drawGuaranteeList = this.guaranteeList.concat(this.salaryDrawList);
    for ( const drawGuarantee of  drawGuaranteeList) {
      // List start month date.
      const startMonthDate = this._helper.getFormattedMonthYear(drawGuarantee.startMonth);
      if (guaranteeStartDate >= startMonthDate) {
        // show none toggole button if selected start date is greater than last entry.
        this.guaranteeNoneVisible = true;
        this.guaranteeEndMonthMaxLimit = this._helper.getFormattedMonthYear("December-9999");
      } else {
        // hide none toggle button if selected start date is of middle months.
        this.guaranteeNoneVisible = false;
        this.guaranteeEndMonthMaxLimit = this._helper.getFormattedMonthYear(drawGuarantee.startMonth).subtract(1, "M");
        break;
      }
    }
    if (!this.guaranteeNoneVisible || (this.guaranteeNoneVisible && !this.guaranteeNoneChecked)) {
      if (source == 'onchange') {
        this.guaranteeEndMonthYear = moment(guaranteeStartMonth).format("MMMM-YYYY");
        this.guaranteeEndMonthYearEle.setValue(moment(guaranteeStartMonth));
        this.guaranteeEndMonthMinLimit = moment(guaranteeStartMonth);
      } else {
        this.guaranteeEndMonthYearEle.setValue(moment(this.guaranteeEndMonthYear));
        this.guaranteeEndMonthMinLimit = moment(this.guaranteeStartMonthYear);
      }

    }
  }
  updateGuarantee() {
    const isGuaranteeValid = this.performGuaranteeValidation();
    if (isGuaranteeValid) {
      this.editGuaranteeResponse["guaranteeAmount"] = this.guaranteeAmount;
      this.editGuaranteeResponse["guaranteeNote"] = this.guaranteeNote;
      this.editGuaranteeResponse["selectedManufacturerGuarantee"] = this.selectedManufacturerGuarantee;
      this.editGuaranteeResponse["guaranteeManufacturerCustomOption"] = (this.guaranteeManufacturerCustomOption) ? this.guaranteeManufacturerCustomOption.trim() : "";
      this.editGuaranteeResponse["startMonth"] = this.guaranteeStartMonthYear;
      this.editGuaranteeResponse["endMonth"] = this.guaranteeEndMonthYear;
      this.activeModal.close(this.editGuaranteeResponse);
    }
  }
  performGuaranteeValidation() {
    let isGuaranteeValid = true;
    this.guaranteeValidationList = { guaranteeAmount: "" };
    if (this.guaranteeAmount === "") {
      isGuaranteeValid = false;
      this.guaranteeValidationList.guaranteeAmount = "This field is required!";
    } else if (this.guaranteeAmount == 0) {
      isGuaranteeValid = false;
      this.guaranteeValidationList.guaranteeAmount = "Zero value not allowed!";
    } else if (!this.guaranteeAmount.toString().match(/^-?[0-9]+(\.?[0-9]+)?$/)) {
      isGuaranteeValid = false;
      this.guaranteeValidationList.guaranteeAmount = "Only number allowed";
    }
    // Manufacturer validation
    const {
      manufacturerIsValid,
      manufacturerValidationList,
      manufacturerCustomOption
    } = this._salespeopleHelper.validateManufacturerWithoutRequired(
      {
        selectedManufacturer: this.selectedManufacturerGuarantee,
        validationList: this.guaranteeValidationList,
        manufacturerCustomOption: this.guaranteeManufacturerCustomOption,
        isValid: isGuaranteeValid,
        manufacturers: this.manufacturers
      }
    );
    isGuaranteeValid = manufacturerIsValid;
    this.guaranteeValidationList = manufacturerValidationList;
    this.guaranteeManufacturerCustomOption = manufacturerCustomOption;
    // Manufacturer validation ends
    return isGuaranteeValid;

  }
  closePopup() {
    this.activeModal.close({});
  }
  chosenGuaranteeStartMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.guaranteeStartMonthYear = moment(normalizedMonth).format("MMMM-YYYY");
    this.guaranteeStartMonthYearEle.setValue(moment(normalizedMonth));
    this.guaranteeEndMonthMinLimit = moment(normalizedMonth);
    this.updateGuaranteeEndMonthPicker(normalizedMonth, 'onchange');
    datepicker.close();
  }
  chosenGuaranteeEndMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    this.guaranteeEndMonthYear = moment(normalizedMonth).format("MMMM-YYYY");
    this.guaranteeEndMonthYearEle.setValue(moment(normalizedMonth));
    datepicker.close();
  }
  onGuaranteeNoneChange(ob: MatSlideToggleChange) {
    if (ob.checked) {
      this.guaranteeEndMonthYear = 'None';
    } else {
      this.guaranteeEndMonthYear = this.guaranteeStartMonthYear;
      this.guaranteeEndMonthYearEle.setValue(moment(this.guaranteeEndMonthYear));
      this.guaranteeEndMonthMinLimit = moment(this.guaranteeEndMonthYear);
    }
    this.guaranteeNoneChecked = ob.checked;
  }
}
