import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CommissionService } from "../commission.service";
import { FlashMessagesService } from "angular2-flash-messages";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: "app-calculatecommissionhistory-screen",
  templateUrl: "./calculatecommissionhistory.screen.component.html",
  styleUrls: ["./calculatecommissionhistory.screen.component.scss"],
  providers: [CommissionService],
})
export class CalculateCommissionHistoryComponent implements OnInit {
  @Input() commissionHistoryData;
  @Input() selectedMonthYear;
  constructor(
    private activeModal: NgbActiveModal,
    private _commissionService: CommissionService,
    private _flashMessagesService: FlashMessagesService,
    private spinner: NgxSpinnerService
  ) {}
  ngOnInit() {}
  closePopup() {
    this.activeModal.close({});
  }
  reloadData() {
    this.spinner.show();
    this._commissionService
      .getCalculateCommissionHistory(this.selectedMonthYear)
      .subscribe(
        (commissionHistory) => {
          let commissionHistoryData = JSON.parse(commissionHistory.data);
          if (commissionHistoryData.length > 0) {
            commissionHistoryData.forEach((historyObj) => {
              historyObj.percentageValue = Math.round(
                (historyObj.totalProcessedSalesPersons * 100) /
                  historyObj.totalSalesPersons
              );
            });
            this.commissionHistoryData = commissionHistoryData;
            this.spinner.hide();
          }
        },
        (error) => {
          this._flashMessagesService.show(`Something went wrong!: ${error}`, {
            cssClass: "alert-danger",
            timeout: 4000,
          });
        }
      );
  }
}
