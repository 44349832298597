import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { ConfigService } from '../core/config';

@Injectable()
export class TerritoriesService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService
  ) {
  }
  getTerritoriesList(showDeletedRecords): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/territories/getTerritoriesList/${showDeletedRecords}`);
  }

  deleteTerritory(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/territories/deleteTerritory`, params);
  }

  deleteTerritoryAlias(id): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/territories/deleteTerritoryAlias/${id}`);
  }

  unAssignRegion(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/territories/unAssignRegion`, params);
  }

  getRegions(): Observable<any> {
    return this.http.get(`${this.configService.apiUrl}/territories/getRegions`);
  }

  assignRegion(params): Observable<any> {
    return this.http.post(`${this.configService.apiUrl}/territories/assignRegion`, params);
  }
}
